/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';
import {
    ServiceResponseAllOf,
    ServiceResponseAllOfFromJSON,
    ServiceResponseAllOfFromJSONTyped,
    ServiceResponseAllOfToJSON,
} from './ServiceResponseAllOf';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';

/**
 * 
 * @export
 * @interface ServiceResponse
 */
export interface ServiceResponse {
    /**
     * Идентификатор услуги
     * @type {number}
     * @memberof ServiceResponse
     */
    id: number;
    /**
     * 
     * @type {ShootingType}
     * @memberof ServiceResponse
     */
    shootingType: ShootingType;
    /**
     * Стоимость услуги
     * @type {number}
     * @memberof ServiceResponse
     */
    amount: number;
    /**
     * Название услуги
     * @type {string}
     * @memberof ServiceResponse
     */
    name: string;
    /**
     * Код услуги
     * @type {string}
     * @memberof ServiceResponse
     */
    code: string;
    /**
     * Признак включенной услуги
     * @type {boolean}
     * @memberof ServiceResponse
     */
    enabled: boolean;
    /**
     * 
     * @type {PaymentRate}
     * @memberof ServiceResponse
     */
    paymentRate: PaymentRate;
    /**
     * Дата включения услуги
     * @type {number}
     * @memberof ServiceResponse
     */
    activatedDate: number;
}

export function ServiceResponseFromJSON(json: any): ServiceResponse {
    return ServiceResponseFromJSONTyped(json, false);
}

export function ServiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'shootingType': ShootingTypeFromJSON(json['shootingType']),
        'amount': json['amount'],
        'name': json['name'],
        'code': json['code'],
        'enabled': json['enabled'],
        'paymentRate': PaymentRateFromJSON(json['paymentRate']),
        'activatedDate': json['activatedDate'],
    };
}

export function ServiceResponseToJSON(value?: ServiceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shootingType': ShootingTypeToJSON(value.shootingType),
        'amount': value.amount,
        'name': value.name,
        'code': value.code,
        'enabled': value.enabled,
        'paymentRate': PaymentRateToJSON(value.paymentRate),
        'activatedDate': value.activatedDate,
    };
}

