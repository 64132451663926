import { PhotoTaskStatus } from '@/api';

export const photoTasksStatusColor: Record<PhotoTaskStatus, string> = {
  [PhotoTaskStatus.Created]: '',
  [PhotoTaskStatus.InProgress]: '#fa3',
  [PhotoTaskStatus.InApproval]: '#fa3',
  [PhotoTaskStatus.InCorrection]: '#fa3',
  [PhotoTaskStatus.Done]: '#0f5746',
};

export enum PhotoTaskModalMode {
  Create = 'create',
  Edit = 'edit',
}
