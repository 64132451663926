/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PriceRequest,
    PriceRequestFromJSON,
    PriceRequestToJSON,
    Prices,
    PricesFromJSON,
    PricesToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
    Seller,
    SellerFromJSON,
    SellerToJSON,
    SellerListResponse,
    SellerListResponseFromJSON,
    SellerListResponseToJSON,
    SellerTemplate,
    SellerTemplateFromJSON,
    SellerTemplateToJSON,
} from '../models';

export interface GetSellerByIDRequest {
    sellerID: number;
}

export interface InternalCreateOrUpdateSellerByExternalIDRequest {
    sellerTemplate?: SellerTemplate;
}

export interface SellerGetServicesPricesRequest {
    priceRequest?: PriceRequest;
}

export interface SellersListByIDsRequest {
    page: number;
    size: number;
    ids?: Array<number>;
    q?: string;
}

/**
 * 
 */
export class SellersApi extends runtime.BaseAPI {

    /**
     * Получить информацию продавца по его идентификатору
     */
    async getSellerByIDRaw(requestParameters: GetSellerByIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Seller>> {
        if (requestParameters.sellerID === null || requestParameters.sellerID === undefined) {
            throw new runtime.RequiredError('sellerID','Required parameter requestParameters.sellerID was null or undefined when calling getSellerByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/sellers/{sellerID}`.replace(`{${"sellerID"}}`, encodeURIComponent(String(requestParameters.sellerID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SellerFromJSON(jsonValue));
    }

    /**
     * Получить информацию продавца по его идентификатору
     */
    async getSellerByID(requestParameters: GetSellerByIDRequest, initOverrides?: RequestInit): Promise<Seller> {
        const response = await this.getSellerByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Создать или обновить данные продавца по внешнему идентификатору
     */
    async internalCreateOrUpdateSellerByExternalIDRaw(requestParameters: InternalCreateOrUpdateSellerByExternalIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Seller>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/sellers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SellerTemplateToJSON(requestParameters.sellerTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SellerFromJSON(jsonValue));
    }

    /**
     * Создать или обновить данные продавца по внешнему идентификатору
     */
    async internalCreateOrUpdateSellerByExternalID(requestParameters: InternalCreateOrUpdateSellerByExternalIDRequest = {}, initOverrides?: RequestInit): Promise<Seller> {
        const response = await this.internalCreateOrUpdateSellerByExternalIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить стоимость услуг фотостудии для товаров
     */
    async sellerGetServicesPricesRaw(requestParameters: SellerGetServicesPricesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Prices>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers/services/prices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PriceRequestToJSON(requestParameters.priceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PricesFromJSON(jsonValue));
    }

    /**
     * Получить стоимость услуг фотостудии для товаров
     */
    async sellerGetServicesPrices(requestParameters: SellerGetServicesPricesRequest = {}, initOverrides?: RequestInit): Promise<Prices> {
        const response = await this.sellerGetServicesPricesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить информацию продавцов по их идентификатору
     */
    async sellersListByIDsRaw(requestParameters: SellersListByIDsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SellerListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling sellersListByIDs.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling sellersListByIDs.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/sellers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SellerListResponseFromJSON(jsonValue));
    }

    /**
     * Получить информацию продавцов по их идентификатору
     */
    async sellersListByIDs(requestParameters: SellersListByIDsRequest, initOverrides?: RequestInit): Promise<SellerListResponse> {
        const response = await this.sellersListByIDsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
