<template>
  <div class="modal-nav">
    <template v-for="(item, index) in items" :key="item">
      <div v-if="item.path" class="modal-nav-link">
        <span>
          {{ item.label }}
        </span>
      </div>
      <span v-else class="modal-nav-last-item font-semi-bold">
        {{ item.label }}
      </span>
      <template v-if="showDivider(index)">
        <icon-ui
          class="modal-nav-divider"
          name="navigation/chevron-right_line"
          size="16"
        />
      </template>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

export interface IModalNavItem {
  label: string;
  path?: string;
}

export default defineComponent({
  name: 'modal-nav',
  props: {
    items: {
      type: Array as PropType<Array<{ path?: string; label: string }>>,
      default: (): string[] => [],
    },
  },
  setup(props) {
    const showDivider = (index: number) => index !== props.items.length - 1;

    return { showDivider };
  },
});
</script>
<style lang="stylus" scoped>
.modal-nav
  display grid
  grid-column-gap 8px
  grid-auto-flow column
  align-items center

.modal-nav-link
  @apply text-s leading-short font-regular
  color Gray(DK8)
  text-decoration none

.modal-nav-last-item
  color Gray(DK28)

::v-deep(.modal-nav-divider)
  fill Gray(DK8)
</style>
