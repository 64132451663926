import { computed, onUnmounted, reactive } from 'vue';

import {
  photoTaskApi,
  PhotoTaskResponse,
  PhotoTaskStatus,
  PhotoTaskTemplate,
  UpdatePhotoTaskRequirements,
  UpdatePhotoTaskSellerContact,
  UpdatePhotoTaskServices,
} from '@/api';

interface IPhotoTask extends Omit<PhotoTaskTemplate, 'sellerId'> {
  sellerId: number | null; // Теперь это number | null
}

interface TState {
  loading: boolean;
  serviceLoading: boolean;
  sellerContactLoading: boolean;
  requirementsLoading: boolean;
  statusLoading: boolean;
  createPhotoTaskPayload: IPhotoTask;
  photoTask: PhotoTaskResponse | null;
}

const initialState: TState = {
  loading: false,
  serviceLoading: false,
  sellerContactLoading: false,
  requirementsLoading: false,
  statusLoading: false,
  createPhotoTaskPayload: {
    sellerId: null,
    sellerContact: '',
    dueDate: 0,
    requirements: '',
    skuIds: [],
    serviceIds: [],
    infographicsOnly: false,
  },
  photoTask: null,
};

const allStatuses = computed(() => {
  return [
    PhotoTaskStatus.Created,
    PhotoTaskStatus.InProgress,
    PhotoTaskStatus.InApproval,
    PhotoTaskStatus.InCorrection,
    PhotoTaskStatus.Done,
  ];
});

const manualChangeStatuses = computed(() => {
  return [PhotoTaskStatus.InProgress, PhotoTaskStatus.InApproval];
});

export const usePhotoTask = () => {
  const state = reactive<TState>(initialState);

  const createPhotoTask = async (): Promise<PhotoTaskResponse> => {
    state.photoTask = await photoTaskApi.createPhotoTask({
      photoTaskTemplate: state.createPhotoTaskPayload as PhotoTaskTemplate,
    });

    return state.photoTask;
  };

  const getPhotoTask = async (id: number) => {
    state.loading = true;
    state.photoTask = await photoTaskApi.getTaskByID({
      photoTaskID: Number(id),
    });
    state.loading = false;
  };

  const isCreatePhotoTaskPayloadValid = computed(() => {
    const {
      sellerId,
      sellerContact,
      dueDate,
      requirements,
      skuIds,
      serviceIds,
    } = state.createPhotoTaskPayload;

    return [
      sellerId,
      sellerContact.trim(),
      dueDate > 0,
      requirements.trim(),
      skuIds.length,
      serviceIds.length,
    ].every((field) => field); // Проверяем, что все поля истинны ( заполнены )
  });

  const photoTaskCreated = computed(() => !!state.photoTask);

  const currentPayload = computed(() => {
    return state.photoTask ? state.photoTask : state.createPhotoTaskPayload;
  });

  const updateSellerContact = async (
    sellerContact: string,
  ): Promise<PhotoTaskResponse> => {
    state.sellerContactLoading = true;

    const photoTask = await photoTaskApi.editPhotoTaskSellerContact({
      photoTaskID: state.photoTask!.id,
      updatePhotoTaskSellerContact: {
        sellerContact,
      } as UpdatePhotoTaskSellerContact,
    });

    state.sellerContactLoading = false;

    return photoTask;
  };

  const updateRequirements = async (
    requirements: string,
  ): Promise<PhotoTaskResponse> => {
    state.requirementsLoading = true;

    const photoTask = await photoTaskApi.editPhotoTaskRequirements({
      photoTaskID: state.photoTask!.id,
      updatePhotoTaskRequirements: {
        requirements,
      } as UpdatePhotoTaskRequirements,
    });

    state.requirementsLoading = false;

    return photoTask;
  };

  const updateServices = async (
    serviceIds: Array<number>,
  ): Promise<PhotoTaskResponse> => {
    state.serviceLoading = true;

    const photoTask = await photoTaskApi.editPhotoTaskServices({
      photoTaskID: state.photoTask!.id,
      updatePhotoTaskServices: {
        serviceIds,
      } as UpdatePhotoTaskServices,
    });

    state.serviceLoading = false;

    return photoTask;
  };

  const getNextStatus = ({ status }: PhotoTaskResponse) => {
    const flowSet = new Set(allStatuses.value);
    const flow = Array.from(flowSet);
    const idx = flow.indexOf(status);

    return flow[idx + 1];
  };

  const nextStatus = computed(() => {
    return state.photoTask ? getNextStatus(state.photoTask) : null;
  });

  const canChangeStatus = computed(() => {
    return manualChangeStatuses.value.includes(
      state.photoTask?.status as PhotoTaskStatus,
    );
  });

  const changeStatus = async (): Promise<PhotoTaskResponse | null> => {
    if (!nextStatus.value || !state.photoTask) {
      return null;
    }

    state.statusLoading = true;

    const photoTask = await photoTaskApi.changePhotoTaskStatus({
      photoTaskID: state.photoTask.id,
      changeTaskStatus: {
        status: nextStatus.value,
      },
    });

    state.photoTask = photoTask;
    state.statusLoading = false;

    return photoTask;
  };

  onUnmounted(() => {
    state.createPhotoTaskPayload.sellerId = null;
    state.createPhotoTaskPayload.sellerContact = '';
    state.createPhotoTaskPayload.dueDate = 0;
    state.createPhotoTaskPayload.requirements = '';
    state.createPhotoTaskPayload.skuIds = [];
    state.createPhotoTaskPayload.serviceIds = [];
    state.createPhotoTaskPayload.infographicsOnly = false;
    state.loading = false;
    state.photoTask = null;
  });

  return {
    state,
    currentPayload,
    photoTaskCreated,
    nextStatus,
    canChangeStatus,
    isCreatePhotoTaskPayloadValid,
    changeStatus,
    createPhotoTask,
    getPhotoTask,
    updateSellerContact,
    updateRequirements,
    updateServices,
  };
};
