<template>
  <UiPopover v-model:open="visible" trigger="click" placement="leftTop">
    <Tooltip
      :text="$t('create new photo request')"
      placement="left"
      @click.stop
    >
      <UiButton
        data-test-id="button__element-create"
        type="primary"
        size="large"
        shape="square"
      >
        <template #icon>
          <PlusOutlined />
        </template>
      </UiButton>
    </Tooltip>

    <template #content>
      <ul class="list" data-test-id="popup__shooting_type">
        <li v-for="shootingType in shootingTypeList" :key="shootingType">
          <button @click="handleCreate(shootingType)">
            <Chip
              :value="$t(shootingType)"
              :color="shootingTypeToColor[shootingType]"
            />
          </button>
        </li>
      </ul>
    </template>
  </UiPopover>
</template>

<script setup lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';

import { ShootingType } from '@/api';
import { useAuth } from '@/features/Auth';
import { EModalTypes, useModal } from '@/features/Modals';
import { Chip, Tooltip, UiButton, UiPopover } from '@/ui';

import { shootingTypeToColor } from '../data';

const { canCreateRequestInInstructionalType } = useAuth();

const shootingTypeList = [
  ShootingType.Model,
  ShootingType.Detailed,
  ShootingType.Render,
  ...(canCreateRequestInInstructionalType.value
    ? [ShootingType.Instructional]
    : []),
] as const;

const visible = ref(false);

const [openCreateModal] = useModal<{ shootingType: ShootingType }>(
  EModalTypes.REQUEST_CREATION,
);

const handleCreate = (shootingType: ShootingType) => {
  openCreateModal({ shootingType });
  visible.value = false;
};
</script>

<style scoped lang="stylus">
.list
  margin -12px -16px
  display flex
  flex-direction column

  button
    width 100%
    padding 16px 90px 16px 24px
    border-bottom 1px solid gray-4
    cursor pointer
</style>
