/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PriceRequest,
    PriceRequestFromJSON,
    PriceRequestToJSON,
    Prices,
    PricesFromJSON,
    PricesToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
    ServiceListResponse,
    ServiceListResponseFromJSON,
    ServiceListResponseToJSON,
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeToJSON,
} from '../models';

export interface SellerGetServicesPricesRequest {
    priceRequest?: PriceRequest;
}

export interface ServiceListRequest {
    page: number;
    size: number;
    shootingType?: ShootingType;
}

/**
 * 
 */
export class ServicesApi extends runtime.BaseAPI {

    /**
     * Получить стоимость услуг фотостудии для товаров
     */
    async sellerGetServicesPricesRaw(requestParameters: SellerGetServicesPricesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Prices>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers/services/prices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PriceRequestToJSON(requestParameters.priceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PricesFromJSON(jsonValue));
    }

    /**
     * Получить стоимость услуг фотостудии для товаров
     */
    async sellerGetServicesPrices(requestParameters: SellerGetServicesPricesRequest = {}, initOverrides?: RequestInit): Promise<Prices> {
        const response = await this.sellerGetServicesPricesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список услуг
     */
    async serviceListRaw(requestParameters: ServiceListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ServiceListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling serviceList.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling serviceList.');
        }

        const queryParameters: any = {};

        if (requestParameters.shootingType !== undefined) {
            queryParameters['shootingType'] = requestParameters.shootingType;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/services`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceListResponseFromJSON(jsonValue));
    }

    /**
     * Получить список услуг
     */
    async serviceList(requestParameters: ServiceListRequest, initOverrides?: RequestInit): Promise<ServiceListResponse> {
        const response = await this.serviceListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
