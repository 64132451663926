import { computed, ref } from 'vue';

import {
  ServiceListResponse,
  ServiceResponse,
  servicesApi,
  ShootingType,
} from '@/api';
import { getLang } from '@/utils/lang';

export const useServices = (shootingType: ShootingType) => {
  const serviceListResponse = ref<ServiceListResponse>({
    pageSize: 0,
    page: 0,
    totalPages: 0,
    totalItems: 0,
    items: [] as ServiceResponse[],
  });

  const services = computed<ServiceResponse[]>(() =>
    serviceListResponse.value.items.map((service) => {
      return {
        ...service,
        name: getLang(service.name),
      };
    }),
  );

  const getServices = async (shootingType: ShootingType) => {
    serviceListResponse.value = await servicesApi.serviceList({
      page: 0,
      size: 1000,
      shootingType,
    });
  };

  return {
    services,
    getServices,
  };
};
