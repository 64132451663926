<template>
  <div class="wrapper">
    <ModalHeader :customLocationPath="locationPath" @close="$emit('close')" />

    <div class="content">
      <div class="left-side">
        <UiSpin :spinning="state.loading">
          <Info
            :mode="modalMode"
            :loading="state.loading"
            @invalidatePhotoTasks="invalidatePhotoTasks"
          />
        </UiSpin>
      </div>

      <div class="right-side">
        <div
          v-if="modalMode === PhotoTaskModalMode.Create"
          class="flex gap-3 px-[32px] py-[16px]"
        >
          <ui-input-search
            :loading="photoTaskSkuState.createRequestPending"
            class="barcode-input"
            :enter-button="$t('button.add')"
            :placeholder="
              $t(`modal.request-creation.enter-barcode-placeholder`)
            "
            @search="addSkuByBarcode($event)"
          />

          <UiTooltip
            :title="
              !isCreatePhotoTaskPayloadValid
                ? 'Нельзя сохранить, так как не все поля заполнены'
                : ''
            "
          >
            <span>
              <UiButton
                type="primary"
                :disabled="!isCreatePhotoTaskPayloadValid"
                @click="savePhotoTask"
              >
                Сохранить
              </UiButton>
            </span>
          </UiTooltip>
        </div>

        <UiSpin
          v-if="state.loading || photoTaskSkuState.loading"
          :spinning="true"
          class="flex items-center justify-center h-full"
        />

        <PhotoTaskSkuTable v-else :mode="modalMode" size="small" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';
import { HTTPError } from 'ky';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { PhotoTaskStatus } from '@/api';
import Info from '@/features/Modals/photoTask/components/info.vue';
import PhotoTaskSkuTable from '@/features/Modals/photoTask/components/photoTaskSkuTable.vue';
import { usePhotoTask } from '@/features/Modals/photoTask/composables/usePhotoTask';
import { usePhotoTaskSku } from '@/features/Modals/photoTask/composables/usePhotoTaskSku';
import { PhotoTaskModalMode } from '@/features/Modals/photoTask/data';
import ModalHeader from '@/features/Modals/request/components/ModalHeader.vue';
import { AppRoutes, router } from '@/router';
import { UiButton, UiInputSearch, UiSpin, UiTooltip } from '@/ui';

const { t } = useI18n();

interface Props {
  id?: number;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const modalMode = ref<PhotoTaskModalMode>(
  props.id ? PhotoTaskModalMode.Edit : PhotoTaskModalMode.Create,
);

const {
  state: photoTaskSkuState,
  addSkuByBarcode,
  addSkuByIds,
} = usePhotoTaskSku();

const {
  state,
  photoTaskCreated,
  isCreatePhotoTaskPayloadValid,
  createPhotoTask,
  getPhotoTask,
} = usePhotoTask();

const client = useQueryClient();

const invalidatePhotoTasks = async (status: PhotoTaskStatus) => {
  await client.invalidateQueries(['phototasks', status]);
};

watch(
  () => photoTaskCreated.value,
  (value) => {
    if (value) {
      modalMode.value = PhotoTaskModalMode.Edit;
    } else {
      modalMode.value = PhotoTaskModalMode.Create;
    }
  },
);

const locationPath = computed(() => {
  if (modalMode.value === PhotoTaskModalMode.Create) {
    return [
      { label: t('photostudio'), path: '/' },
      { label: t('phototasks'), path: '/phototasks' },
    ];
  } else {
    return [
      { label: t('photostudio'), path: '/' },
      { label: t('phototasks'), path: '/phototasks' },
      { label: t('modal.phototask.title', { id: state.photoTask?.id }) },
    ];
  }
});

onMounted(async () => {
  if (!props.id) {
    return;
  }

  try {
    await getPhotoTask(props.id);
    await addSkuByIds(state.photoTask?.skuIds);
  } catch (e) {
    if (e instanceof HTTPError) {
      await router.push({ name: AppRoutes.empty.name });

      return;
    }
  }
});

const savePhotoTask = async () => {
  const newPhotoTask = await createPhotoTask();

  await invalidatePhotoTasks(newPhotoTask.status);
};
</script>

<style scoped lang="stylus">
.wrapper
  @apply bg-white
  border-radius 8px
  width 75vw
  height 90vh
  overflow hidden
  display flex
  flex-direction column

.content
  flex 1
  overflow auto
  display grid
  grid-template-columns 550px minmax(550px, 1fr)
  border-top 1px solid Gray(LT40)

.left-side
  display flex
  flex-direction column
  overflow auto
  border-right 1px solid Gray(LT40)

.right-side
  display flex
  flex-direction column
  overflow auto
</style>
