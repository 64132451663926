/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoTaskResponseAllOf,
    PhotoTaskResponseAllOfFromJSON,
    PhotoTaskResponseAllOfFromJSONTyped,
    PhotoTaskResponseAllOfToJSON,
} from './PhotoTaskResponseAllOf';
import {
    PhotoTaskStatus,
    PhotoTaskStatusFromJSON,
    PhotoTaskStatusFromJSONTyped,
    PhotoTaskStatusToJSON,
} from './PhotoTaskStatus';

/**
 * 
 * @export
 * @interface PhotoTaskResponse
 */
export interface PhotoTaskResponse {
    /**
     * Идентификатор сущности ТЗ
     * @type {number}
     * @memberof PhotoTaskResponse
     */
    id: number;
    /**
     * 
     * @type {PhotoTaskStatus}
     * @memberof PhotoTaskResponse
     */
    status: PhotoTaskStatus;
    /**
     * Контакты селлера
     * @type {string}
     * @memberof PhotoTaskResponse
     */
    sellerContact: string;
    /**
     * Идентификатор селлера
     * @type {number}
     * @memberof PhotoTaskResponse
     */
    sellerId: number;
    /**
     * Планируема дата готовности
     * @type {number}
     * @memberof PhotoTaskResponse
     */
    dueDate: number;
    /**
     * Требования к съемке
     * @type {string}
     * @memberof PhotoTaskResponse
     */
    requirements: string;
    /**
     * Список товарных позиций
     * @type {Array<number>}
     * @memberof PhotoTaskResponse
     */
    skuIds: Array<number>;
    /**
     * Список идентификаторов услуг
     * @type {Array<number>}
     * @memberof PhotoTaskResponse
     */
    serviceIds: Array<number>;
    /**
     * Идентификатор заявки
     * @type {number}
     * @memberof PhotoTaskResponse
     */
    photoRequestId: number;
    /**
     * Признак нужна ли только инфографика
     * @type {boolean}
     * @memberof PhotoTaskResponse
     */
    infographicsOnly: boolean;
}

export function PhotoTaskResponseFromJSON(json: any): PhotoTaskResponse {
    return PhotoTaskResponseFromJSONTyped(json, false);
}

export function PhotoTaskResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoTaskResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': PhotoTaskStatusFromJSON(json['status']),
        'sellerContact': json['seller_contact'],
        'sellerId': json['seller_id'],
        'dueDate': json['due_date'],
        'requirements': json['requirements'],
        'skuIds': json['sku_ids'],
        'serviceIds': json['service_ids'],
        'photoRequestId': json['photo_request_id'],
        'infographicsOnly': json['infographics_only'],
    };
}

export function PhotoTaskResponseToJSON(value?: PhotoTaskResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': PhotoTaskStatusToJSON(value.status),
        'seller_contact': value.sellerContact,
        'seller_id': value.sellerId,
        'due_date': value.dueDate,
        'requirements': value.requirements,
        'sku_ids': value.skuIds,
        'service_ids': value.serviceIds,
        'photo_request_id': value.photoRequestId,
        'infographics_only': value.infographicsOnly,
    };
}

