/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhotoTaskTemplate
 */
export interface PhotoTaskTemplate {
    /**
     * Идентификатор селлера
     * @type {number}
     * @memberof PhotoTaskTemplate
     */
    sellerId: number;
    /**
     * Контакты селлера
     * @type {string}
     * @memberof PhotoTaskTemplate
     */
    sellerContact: string;
    /**
     * Планируема дата готовности
     * @type {number}
     * @memberof PhotoTaskTemplate
     */
    dueDate: number;
    /**
     * Требования к съемке
     * @type {string}
     * @memberof PhotoTaskTemplate
     */
    requirements: string;
    /**
     * Список идентификаторов ску
     * @type {Array<number>}
     * @memberof PhotoTaskTemplate
     */
    skuIds: Array<number>;
    /**
     * Список идентификаторов услуг
     * @type {Array<number>}
     * @memberof PhotoTaskTemplate
     */
    serviceIds: Array<number>;
    /**
     * Признак нужна ли только инфографика
     * @type {boolean}
     * @memberof PhotoTaskTemplate
     */
    infographicsOnly: boolean;
}

export function PhotoTaskTemplateFromJSON(json: any): PhotoTaskTemplate {
    return PhotoTaskTemplateFromJSONTyped(json, false);
}

export function PhotoTaskTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoTaskTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sellerId': json['seller_id'],
        'sellerContact': json['seller_contact'],
        'dueDate': json['due_date'],
        'requirements': json['requirements'],
        'skuIds': json['sku_ids'],
        'serviceIds': json['service_ids'],
        'infographicsOnly': json['infographics_only'],
    };
}

export function PhotoTaskTemplateToJSON(value?: PhotoTaskTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seller_id': value.sellerId,
        'seller_contact': value.sellerContact,
        'due_date': value.dueDate,
        'requirements': value.requirements,
        'sku_ids': value.skuIds,
        'service_ids': value.serviceIds,
        'infographics_only': value.infographicsOnly,
    };
}

