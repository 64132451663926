/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Запрос на обновление элемента заявки после автозагрузки
 * @export
 * @interface UpdatePhotoRequestItemAfterAutoUploadRequest
 */
export interface UpdatePhotoRequestItemAfterAutoUploadRequest {
    /**
     * Тип события
     * @type {string}
     * @memberof UpdatePhotoRequestItemAfterAutoUploadRequest
     */
    eventKind: UpdatePhotoRequestItemAfterAutoUploadRequestEventKindEnum;
    /**
     * Описание ошибки. Ошибка может быть разного формата
     * @type {string}
     * @memberof UpdatePhotoRequestItemAfterAutoUploadRequest
     */
    error?: string;
}

/**
* @export
* @enum {string}
*/
export enum UpdatePhotoRequestItemAfterAutoUploadRequestEventKindEnum {
    Uploaded = 'PHOTO_AUTO_UPLOADED',
    UploadError = 'PHOTO_AUTO_UPLOAD_ERROR'
}

export function UpdatePhotoRequestItemAfterAutoUploadRequestFromJSON(json: any): UpdatePhotoRequestItemAfterAutoUploadRequest {
    return UpdatePhotoRequestItemAfterAutoUploadRequestFromJSONTyped(json, false);
}

export function UpdatePhotoRequestItemAfterAutoUploadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePhotoRequestItemAfterAutoUploadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventKind': json['eventKind'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function UpdatePhotoRequestItemAfterAutoUploadRequestToJSON(value?: UpdatePhotoRequestItemAfterAutoUploadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventKind': value.eventKind,
        'error': value.error,
    };
}

