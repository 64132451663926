import {
  createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
  RouterView,
} from 'vue-router';

import { useAuth } from '@/features/Auth';
import { featureFlags } from '@/features/FeatureFlags';
import Administration from '@/pages/administration/index.vue';
import Barcodes from '@/pages/barcodes/index.vue';
import Empty from '@/pages/empty/index.vue';
import Invoice from '@/pages/invoice/index.vue';
import InvoiceCreation from '@/pages/invoiceCreation/index.vue';
import Invoices from '@/pages/invoices/index.vue';
import Login from '@/pages/login/index.vue';
import NotAuthorized from '@/pages/notAuthorized/index.vue';
import Phototasks from '@/pages/phototasks/index.vue';
import Products from '@/pages/products/index.vue';
import Requests from '@/pages/requests/index.vue';
import Reservation from '@/pages/reservation/index.vue';
import Reservations from '@/pages/reservations/index.vue';
import Product from '@/pages/sku/index.vue';
import Transfer from '@/pages/transfer/index.vue';

import { AppRoutes, baseRoute } from './data';
import { applyGuards } from './guard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: RouterView,
    meta: {
      protected: true,
      layout: 'default',
    },
    redirect: { name: AppRoutes.invoices.name },
    children: [
      {
        ...AppRoutes.invoices,
        component: Invoices,
        beforeEnter: (to, from, next) => {
          if (!featureFlags.invoicePage) {
            if (to.name !== AppRoutes.empty.name) {
              next({ name: AppRoutes.empty.name }); // Перенаправляем на пустую страницу
            } else {
              next();
            }
          } else {
            next();
          }
        },
      },
      {
        ...AppRoutes.invoiceCreation,
        component: InvoiceCreation,
        beforeEnter: (to, from, next) => {
          if (!featureFlags.invoicePage) {
            if (to.name !== AppRoutes.empty.name) {
              next({ name: AppRoutes.empty.name });
            } else {
              next();
            }
          } else {
            next();
          }
        },
      },
      {
        ...AppRoutes.invoice,
        component: Invoice,
        meta: {
          parent: AppRoutes.invoices,
        },
        beforeEnter: (to, from, next) => {
          if (!featureFlags.invoicePage) {
            if (to.name !== AppRoutes.empty.name) {
              next({ name: AppRoutes.empty.name });
            } else {
              next();
            }
          } else {
            next();
          }
        },
      },
      {
        ...AppRoutes.requests,
        component: Requests,
        meta: {
          parent: AppRoutes.requests,
        },
      },
      {
        ...AppRoutes.phototasks,
        component: Phototasks,
        meta: {
          parent: AppRoutes.phototasks,
        },
      },
      {
        ...AppRoutes.products,
        component: Products,
      },
      {
        ...AppRoutes.product,
        component: Product,
        meta: {
          parent: AppRoutes.products,
        },
      },
      {
        ...AppRoutes.transfer,
        component: Transfer,
      },
      {
        ...AppRoutes.barcodes,
        component: Barcodes,
      },
      {
        ...AppRoutes.empty,
        component: Empty,
      },
      {
        ...AppRoutes.reservations,
        component: Reservations,
        beforeEnter: (to, from, next) => {
          if (!featureFlags.reservationPage) {
            if (to.name !== AppRoutes.empty.name) {
              next({ name: AppRoutes.empty.name });
            } else {
              next();
            }
          } else {
            next();
          }
        },
      },
      {
        ...AppRoutes.reservation,
        component: Reservation,
        meta: {
          parent: AppRoutes.reservations,
        },
        beforeEnter: (to, from, next) => {
          if (!featureFlags.reservationPage) {
            if (to.name !== AppRoutes.empty.name) {
              next({ name: AppRoutes.empty.name });
            } else {
              next();
            }
          } else {
            next();
          }
        },
      },
      {
        ...AppRoutes.administration,
        component: Administration,
        beforeEnter: () => {
          const { isPhotostudioAdmin } = useAuth();

          if (!isPhotostudioAdmin.value) return { path: '/' };
        },
      },
    ],
  },
  {
    ...AppRoutes.login,
    component: Login,
    meta: {
      layout: 'centered',
    },
  },
  {
    ...AppRoutes.notAuthorized,
    component: NotAuthorized,
    meta: {
      layout: 'centered',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: AppRoutes.invoices.name },
  },
];

export let router: Router;

export const routerFactory = (base?: string): Router => {
  router = createRouter({
    history: createWebHistory(base),
    routes,
  });
  applyGuards(router);

  return router;
};

export { AppRoutes, baseRoute };

declare module 'vue-router' {
  interface RouteMeta {
    parent?: (typeof AppRoutes)[keyof typeof AppRoutes];
  }
}
