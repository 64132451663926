/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePhotoTaskSellerContact
 */
export interface UpdatePhotoTaskSellerContact {
    /**
     * Контакты селлера
     * @type {string}
     * @memberof UpdatePhotoTaskSellerContact
     */
    sellerContact: string;
}

export function UpdatePhotoTaskSellerContactFromJSON(json: any): UpdatePhotoTaskSellerContact {
    return UpdatePhotoTaskSellerContactFromJSONTyped(json, false);
}

export function UpdatePhotoTaskSellerContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePhotoTaskSellerContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sellerContact': json['seller_contact'],
    };
}

export function UpdatePhotoTaskSellerContactToJSON(value?: UpdatePhotoTaskSellerContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seller_contact': value.sellerContact,
    };
}

