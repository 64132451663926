/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationResponse,
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';
import {
    PhotoTaskListResponseAllOf,
    PhotoTaskListResponseAllOfFromJSON,
    PhotoTaskListResponseAllOfFromJSONTyped,
    PhotoTaskListResponseAllOfToJSON,
} from './PhotoTaskListResponseAllOf';
import {
    PhotoTaskResponse,
    PhotoTaskResponseFromJSON,
    PhotoTaskResponseFromJSONTyped,
    PhotoTaskResponseToJSON,
} from './PhotoTaskResponse';

/**
 * 
 * @export
 * @interface PhotoTaskListResponse
 */
export interface PhotoTaskListResponse {
    /**
     * количество элементов на одной странице
     * @type {number}
     * @memberof PhotoTaskListResponse
     */
    pageSize: number;
    /**
     * номер страницы
     * @type {number}
     * @memberof PhotoTaskListResponse
     */
    page: number;
    /**
     * общее количество страниц
     * @type {number}
     * @memberof PhotoTaskListResponse
     */
    totalPages: number;
    /**
     * общее количество элементов
     * @type {number}
     * @memberof PhotoTaskListResponse
     */
    totalItems: number;
    /**
     * Список запросов на съемку по ТЗ
     * @type {Array<PhotoTaskResponse>}
     * @memberof PhotoTaskListResponse
     */
    items: Array<PhotoTaskResponse>;
}

export function PhotoTaskListResponseFromJSON(json: any): PhotoTaskListResponse {
    return PhotoTaskListResponseFromJSONTyped(json, false);
}

export function PhotoTaskListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoTaskListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalPages': json['totalPages'],
        'totalItems': json['totalItems'],
        'items': ((json['items'] as Array<any>).map(PhotoTaskResponseFromJSON)),
    };
}

export function PhotoTaskListResponseToJSON(value?: PhotoTaskListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'page': value.page,
        'totalPages': value.totalPages,
        'totalItems': value.totalItems,
        'items': ((value.items as Array<any>).map(PhotoTaskResponseToJSON)),
    };
}

