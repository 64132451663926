/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeTaskStatus,
    ChangeTaskStatusFromJSON,
    ChangeTaskStatusToJSON,
    PhotoTaskListResponse,
    PhotoTaskListResponseFromJSON,
    PhotoTaskListResponseToJSON,
    PhotoTaskResponse,
    PhotoTaskResponseFromJSON,
    PhotoTaskResponseToJSON,
    PhotoTaskStatus,
    PhotoTaskStatusFromJSON,
    PhotoTaskStatusToJSON,
    PhotoTaskTemplate,
    PhotoTaskTemplateFromJSON,
    PhotoTaskTemplateToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
    UpdatePhotoTaskRequirements,
    UpdatePhotoTaskRequirementsFromJSON,
    UpdatePhotoTaskRequirementsToJSON,
    UpdatePhotoTaskSellerContact,
    UpdatePhotoTaskSellerContactFromJSON,
    UpdatePhotoTaskSellerContactToJSON,
    UpdatePhotoTaskServices,
    UpdatePhotoTaskServicesFromJSON,
    UpdatePhotoTaskServicesToJSON,
} from '../models';

export interface ChangePhotoTaskStatusRequest {
    photoTaskID: number;
    changeTaskStatus?: ChangeTaskStatus;
}

export interface CreatePhotoTaskRequest {
    photoTaskTemplate?: PhotoTaskTemplate;
}

export interface DeletePhotoTaskRequest {
    photoTaskID: number;
}

export interface EditPhotoTaskRequirementsRequest {
    photoTaskID: number;
    updatePhotoTaskRequirements?: UpdatePhotoTaskRequirements;
}

export interface EditPhotoTaskSellerContactRequest {
    photoTaskID: number;
    updatePhotoTaskSellerContact?: UpdatePhotoTaskSellerContact;
}

export interface EditPhotoTaskServicesRequest {
    photoTaskID: number;
    updatePhotoTaskServices?: UpdatePhotoTaskServices;
}

export interface GetTaskByIDRequest {
    photoTaskID: number;
}

export interface GetTaskByRequestIDRequest {
    photoRequestID: number;
}

export interface PhotoTaskListRequest {
    page: number;
    size: number;
    status?: PhotoTaskStatus;
}

/**
 * 
 */
export class PhotoTaskApi extends runtime.BaseAPI {

    /**
     * Изменить статуса запроса на съемку по ТЗ
     */
    async changePhotoTaskStatusRaw(requestParameters: ChangePhotoTaskStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoTaskResponse>> {
        if (requestParameters.photoTaskID === null || requestParameters.photoTaskID === undefined) {
            throw new runtime.RequiredError('photoTaskID','Required parameter requestParameters.photoTaskID was null or undefined when calling changePhotoTaskStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/phototasks/{photoTaskID}/status`.replace(`{${"photoTaskID"}}`, encodeURIComponent(String(requestParameters.photoTaskID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeTaskStatusToJSON(requestParameters.changeTaskStatus),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoTaskResponseFromJSON(jsonValue));
    }

    /**
     * Изменить статуса запроса на съемку по ТЗ
     */
    async changePhotoTaskStatus(requestParameters: ChangePhotoTaskStatusRequest, initOverrides?: RequestInit): Promise<PhotoTaskResponse> {
        const response = await this.changePhotoTaskStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Создать запрос на съемку по ТЗ
     */
    async createPhotoTaskRaw(requestParameters: CreatePhotoTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoTaskResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/phototasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhotoTaskTemplateToJSON(requestParameters.photoTaskTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoTaskResponseFromJSON(jsonValue));
    }

    /**
     * Создать запрос на съемку по ТЗ
     */
    async createPhotoTask(requestParameters: CreatePhotoTaskRequest = {}, initOverrides?: RequestInit): Promise<PhotoTaskResponse> {
        const response = await this.createPhotoTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Удалить запрос на съемку по ТЗ
     */
    async deletePhotoTaskRaw(requestParameters: DeletePhotoTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.photoTaskID === null || requestParameters.photoTaskID === undefined) {
            throw new runtime.RequiredError('photoTaskID','Required parameter requestParameters.photoTaskID was null or undefined when calling deletePhotoTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/phototasks/{photoTaskID}`.replace(`{${"photoTaskID"}}`, encodeURIComponent(String(requestParameters.photoTaskID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удалить запрос на съемку по ТЗ
     */
    async deletePhotoTask(requestParameters: DeletePhotoTaskRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deletePhotoTaskRaw(requestParameters, initOverrides);
    }

    /**
     * Обновить требования по запросу на съемку по ТЗ
     */
    async editPhotoTaskRequirementsRaw(requestParameters: EditPhotoTaskRequirementsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoTaskResponse>> {
        if (requestParameters.photoTaskID === null || requestParameters.photoTaskID === undefined) {
            throw new runtime.RequiredError('photoTaskID','Required parameter requestParameters.photoTaskID was null or undefined when calling editPhotoTaskRequirements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/phototasks/{photoTaskID}/requirements`.replace(`{${"photoTaskID"}}`, encodeURIComponent(String(requestParameters.photoTaskID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePhotoTaskRequirementsToJSON(requestParameters.updatePhotoTaskRequirements),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoTaskResponseFromJSON(jsonValue));
    }

    /**
     * Обновить требования по запросу на съемку по ТЗ
     */
    async editPhotoTaskRequirements(requestParameters: EditPhotoTaskRequirementsRequest, initOverrides?: RequestInit): Promise<PhotoTaskResponse> {
        const response = await this.editPhotoTaskRequirementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Обновить контакты селлера по запросу на съемку по ТЗ
     */
    async editPhotoTaskSellerContactRaw(requestParameters: EditPhotoTaskSellerContactRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoTaskResponse>> {
        if (requestParameters.photoTaskID === null || requestParameters.photoTaskID === undefined) {
            throw new runtime.RequiredError('photoTaskID','Required parameter requestParameters.photoTaskID was null or undefined when calling editPhotoTaskSellerContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/phototasks/{photoTaskID}/sellerContact`.replace(`{${"photoTaskID"}}`, encodeURIComponent(String(requestParameters.photoTaskID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePhotoTaskSellerContactToJSON(requestParameters.updatePhotoTaskSellerContact),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoTaskResponseFromJSON(jsonValue));
    }

    /**
     * Обновить контакты селлера по запросу на съемку по ТЗ
     */
    async editPhotoTaskSellerContact(requestParameters: EditPhotoTaskSellerContactRequest, initOverrides?: RequestInit): Promise<PhotoTaskResponse> {
        const response = await this.editPhotoTaskSellerContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Обновить услуги по запросу на съемку по ТЗ
     */
    async editPhotoTaskServicesRaw(requestParameters: EditPhotoTaskServicesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoTaskResponse>> {
        if (requestParameters.photoTaskID === null || requestParameters.photoTaskID === undefined) {
            throw new runtime.RequiredError('photoTaskID','Required parameter requestParameters.photoTaskID was null or undefined when calling editPhotoTaskServices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/phototasks/{photoTaskID}/services`.replace(`{${"photoTaskID"}}`, encodeURIComponent(String(requestParameters.photoTaskID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePhotoTaskServicesToJSON(requestParameters.updatePhotoTaskServices),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoTaskResponseFromJSON(jsonValue));
    }

    /**
     * Обновить услуги по запросу на съемку по ТЗ
     */
    async editPhotoTaskServices(requestParameters: EditPhotoTaskServicesRequest, initOverrides?: RequestInit): Promise<PhotoTaskResponse> {
        const response = await this.editPhotoTaskServicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить запрос на съемку по ТЗ
     */
    async getTaskByIDRaw(requestParameters: GetTaskByIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoTaskResponse>> {
        if (requestParameters.photoTaskID === null || requestParameters.photoTaskID === undefined) {
            throw new runtime.RequiredError('photoTaskID','Required parameter requestParameters.photoTaskID was null or undefined when calling getTaskByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/phototasks/{photoTaskID}`.replace(`{${"photoTaskID"}}`, encodeURIComponent(String(requestParameters.photoTaskID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoTaskResponseFromJSON(jsonValue));
    }

    /**
     * Получить запрос на съемку по ТЗ
     */
    async getTaskByID(requestParameters: GetTaskByIDRequest, initOverrides?: RequestInit): Promise<PhotoTaskResponse> {
        const response = await this.getTaskByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить запрос по ТЗ по ID заявки
     */
    async getTaskByRequestIDRaw(requestParameters: GetTaskByRequestIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoTaskResponse>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling getTaskByRequestID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/phototasks/photorequests/{photoRequestID}`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoTaskResponseFromJSON(jsonValue));
    }

    /**
     * Получить запрос по ТЗ по ID заявки
     */
    async getTaskByRequestID(requestParameters: GetTaskByRequestIDRequest, initOverrides?: RequestInit): Promise<PhotoTaskResponse> {
        const response = await this.getTaskByRequestIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список запросов на съемку по ТЗ
     */
    async photoTaskListRaw(requestParameters: PhotoTaskListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoTaskListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling photoTaskList.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling photoTaskList.');
        }

        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/phototasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoTaskListResponseFromJSON(jsonValue));
    }

    /**
     * Получить список запросов на съемку по ТЗ
     */
    async photoTaskList(requestParameters: PhotoTaskListRequest, initOverrides?: RequestInit): Promise<PhotoTaskListResponse> {
        const response = await this.photoTaskListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
