<template>
  <PageLayout>
    <template #header>
      <PageHeader :title="$t('phototasks')">
        <!--        <Search-->
        <!--          v-model="search"-->
        <!--          width="270px"-->
        <!--          :placeholder="$t('phototasksPage.searchPlaceholder')"-->
        <!--          data-test-id="input__search"-->
        <!--        />-->
        <UiButton
          data-test-id="button__element-create"
          type="primary"
          size="large"
          shape="square"
          :disabled="!canChangePhotoTask"
          @click="showPhotoTaskCreateModal = true"
        >
          Создать запрос
        </UiButton>
      </PageHeader>
    </template>

    <div class="body">
      <StatusFilter v-model="selectedStatus" :options="statusOptions" />

      <template v-for="status in statusOptions" :key="status">
        <PhototasksTable
          v-if="status === selectedStatus"
          :status="selectedStatus"
        />
      </template>
    </div>

    <UiModal
      v-if="showPhotoTaskCreateModal"
      class="global-modal"
      centered
      width="unset"
      :closable="false"
      @cancel="showPhotoTaskCreateModal = false"
    >
      <PhotoTask @close="showPhotoTaskCreateModal = false" />
    </UiModal>

    <ScannerEmulator />
  </PageLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({ name: 'phototasks-page' });
</script>

<script setup lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';

import { PhotoTaskStatus } from '@/api';
import { useAuth } from '@/features/Auth';
import PhotoTask from '@/features/Modals/photoTask/index.vue';
import ScannerEmulator from '@/features/ScannerEmulator/scanner-emulator.vue';
import PhototasksTable from '@/pages/phototasks/components/PhototasksTable.vue';
import StatusFilter from '@/pages/phototasks/components/StatusFilter.vue';
import PageHeader from '@/template/page-header/index.vue';
import PageLayout from '@/template/page-layout/index.vue';
import { UiButton, UiModal } from '@/ui';
import Search from '@/ui/search/index.vue';

const search = ref('');
const selectedStatus = ref<PhotoTaskStatus>(PhotoTaskStatus.Created);
const showPhotoTaskCreateModal = ref(false);
const { canChangePhotoTask } = useAuth();

const statusOptions = ref([
  PhotoTaskStatus.Created,
  PhotoTaskStatus.InProgress,
  PhotoTaskStatus.InApproval,
  PhotoTaskStatus.InCorrection,
  PhotoTaskStatus.Done,
]);
</script>

<style lang="stylus" scoped>
.body
  height 100%
  margin 0 auto
  display flex
  flex-direction column
  gap 16px
</style>
