<template>
  <ModalWrapper
    :title="user?.id ? $t('users.form.edit') : $t('users.form.create')"
    @close="emit('close')"
  >
    <div class="form">
      <div class="field field-lastname">
        <div class="label">{{ $t('form.lastname.title') }}</div>
        <UiInput
          v-model:value="state.lastName"
          :class="['value', { invalid: validateStarted && !isLastnameValid }]"
          :placeholder="$t('form.lastname.placeholder')"
          :maxlength="20"
        />
        <div v-if="validateStarted && !isLastnameValid" class="error">
          {{ $t('form.lastname.validate') }}
        </div>
      </div>
      <div class="field field-firstname">
        <div class="label">{{ $t('form.firstname.title') }}</div>
        <UiInput
          v-model:value="state.firstName"
          :class="['value', { invalid: validateStarted && !isFirstnameValid }]"
          :placeholder="$t('form.firstname.placeholder')"
          :maxlength="20"
        />
        <div v-if="validateStarted && !isFirstnameValid" class="error">
          {{ $t('form.firstname.validate') }}
        </div>
      </div>
      <div class="field field-patronymic">
        <div class="label">{{ $t('form.patronymic.title') }}</div>
        <UiInput
          v-model:value="state.patronymic"
          class="value"
          :placeholder="$t('form.patronymic.placeholder')"
          :maxlength="20"
        />
      </div>
      <div class="field field-phone">
        <div class="label">{{ $t('form.phone.title') }}</div>
        <UiInput
          v-model:value="state.phone"
          v-maska="phoneMask"
          :class="['value', { invalid: validateStarted && !isPhoneValid }]"
          :placeholder="phonePlaceholder"
        />
        <div v-if="validateStarted && !isPhoneValid" class="error">
          {{ $t('form.phone.validate') }}
        </div>
      </div>
      <div class="field field-role">
        <div class="label">{{ $t('form.role.title') }}</div>
        <UiSelectCustom
          :class="['value', { invalid: validateStarted && !isRoleValid }]"
          :placeholder="$t('form.role.placeholder')"
          :list="options"
          :default-value="state?.role"
          @change="handleRoleChange"
        />
        <div v-if="validateStarted && !isRoleValid" class="error">
          {{ $t('form.role.validate') }}
        </div>
      </div>
    </div>
    <template #footer>
      <UiButton class="mr-2" type="default" @click="handleClose">
        {{ $t('users.form.cancel') }}
      </UiButton>
      <UiButton
        type="primary"
        :loading="state.requestPending"
        @click="handleSave"
      >
        {{
          user?.id ? $t('users.form.save edit') : $t('users.form.save create')
        }}
      </UiButton>
    </template>
  </ModalWrapper>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref } from 'vue';
export default defineComponent({ name: 'user-form-modal' });
</script>

<script setup lang="ts">
import ModalWrapper from '@/features/Modals/ModalWrapper.vue';
import {
  IChangeWmsAccount,
  ICreateWmsAccount,
  IWmsAccount,
  IWmsRole,
} from '@/pages/administration/types';
import { UiButton, UiInput, UiSelectCustom } from '@/ui';
import {
  getFullPhone,
  phoneMask,
  phonePlaceholder,
  unmaskPhone,
  validatePhone,
} from '@/utils/phone';

const props = defineProps({
  user: {
    type: Object as PropType<IWmsAccount>,
    default: () => {},
  },
  roles: {
    type: Array as PropType<IWmsRole[]>,
    required: true,
  },
  onCreate: {
    type: Function as PropType<(user: ICreateWmsAccount) => void>,
    default: () => {},
  },
  onEdit: {
    type: Function as PropType<(user: IChangeWmsAccount) => void>,
    default: () => {},
  },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const options = computed(() => {
  return props.roles.map((role) => ({
    value: role.name,
    label: role.localizedName,
  }));
});

const state = reactive({
  firstName: props.user?.firstName,
  lastName: props.user?.lastName,
  patronymic: props.user?.patronymic,
  phone: props.user?.phone ? getFullPhone(`${props.user?.phone}`) : '',
  role: props.user?.role.name,
  requestPending: false,
});

const validateStarted = ref(false);
const isFirstnameValid = computed(() => !!state.firstName?.trim());
const isLastnameValid = computed(() => !!state.lastName?.trim());
const isPhoneValid = computed(() => validatePhone(state.phone));
const isRoleValid = computed(() => !!state.role);

const isFormValid = computed(() => {
  return (
    isFirstnameValid.value &&
    isLastnameValid.value &&
    isPhoneValid.value &&
    isRoleValid.value
  );
});

const handleSave = async () => {
  validateStarted.value = true;
  if (!isFormValid.value) return;

  state.requestPending = true;

  try {
    const unmaskedPhone = unmaskPhone(state.phone);

    if (props.user?.id) {
      const data: IChangeWmsAccount = {
        firstName: state.firstName?.trim(),
        lastName: state.lastName?.trim(),
        patronymic: state.patronymic?.trim(),
        phone: props.user.phone,
        newPhone: unmaskedPhone,
        role: state.role,
      };

      await props.onEdit(data);
    } else {
      const data = {
        firstName: state.firstName?.trim(),
        lastName: state.lastName?.trim(),
        validateStarted,
        patronymic: state.patronymic?.trim(),
        phone: unmaskedPhone,
        role: state.role,
      } as ICreateWmsAccount;

      await props.onCreate(data);
    }

    handleClose();
  } finally {
    state.requestPending = false;
    validateStarted.value = false;
  }
};

const handleClose = () => {
  emit('close');
};

const handleRoleChange = (val: string) => {
  state.role = val;
};
</script>

<style lang="stylus" scoped>
.form
  display grid
  grid-template-rows 1fr 1fr
  grid-template-columns 1fr 1fr 1fr 1fr 1fr 1fr
  grid-column-gap 12px
  grid-row-gap 20px
  color #262626
.field
  &-lastname
    grid-row 1
    grid-column-start 1
    grid-column-end 3
  &-firstname
    grid-row 1
    grid-column-start 3
    grid-column-end 5
  &-patronymic
    grid-row 1
    grid-column-start 5
    grid-column-end 7
  &-phone
    grid-row 2
    grid-column-start 1
    grid-column-end 3
  &-role
    grid-row 2
    grid-column-start 3
    grid-column-end 7

.label
  margin-bottom 6px

.value
  width 100%
  //max-width 288px

.error
  color Red()
  font-size 13px
  margin-top 3px
.invalid
  border-color Red()
  :deep() .ant-select-selector
    border-color Red()
</style>
