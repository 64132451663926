import { onUnmounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

import { skuApi, SkuListEntry } from '@/api';
import { usePhotoTask } from '@/features/Modals/photoTask/composables/usePhotoTask';
import { notification } from '@/features/Notifications';

type TState = {
  items: SkuListEntry[];
  createRequestPending: boolean;
  loading: boolean;
};

const initialState: TState = {
  items: [],
  createRequestPending: false,
  loading: false,
};

const { state: photoTaskState } = usePhotoTask();

export const usePhotoTaskSku = () => {
  const state = reactive<TState>(initialState);
  const { t } = useI18n();

  const getSkuByBarcode = async (
    barcode: string,
  ): Promise<SkuListEntry | null> => {
    const {
      items: [sku],
    } = await skuApi.skuList({ page: 0, size: 1, barcode });

    const error = {
      error: !sku,
      message: !sku ? 'modal.error.codes.unknownSkuBarcode' : '',
    };

    if (error.error) {
      notification.error(t(`${error.message}`));

      throw Error('not found');
    }

    return sku;
  };

  const addSkuByIds = async (
    ids: Array<number> | undefined,
  ): Promise<Array<SkuListEntry | null>> => {
    if (!ids || ids.length === 0) return [];

    state.loading = true;
    const promises = ids.map((id) => skuApi.getSkuByID({ skuID: id }));

    state.items = await Promise.all(promises);
    state.loading = false;

    return state.items;
  };

  const addSkuByBarcode = async (barcode: string) => {
    if (!barcode) return;

    const alreadyInTheItems = state.items.some(
      (item) => item.barcode === barcode,
    );

    if (alreadyInTheItems) return;

    state.createRequestPending = true;

    try {
      const sku = await getSkuByBarcode(barcode);

      if (!sku) return;

      state.items.push(sku);
      photoTaskState.createPhotoTaskPayload.skuIds.push(sku.id);
    } finally {
      state.createRequestPending = false;
    }
  };

  const removeSkuById = (skuId: number) => {
    state.items = state.items.filter((item) => item.id !== skuId);

    photoTaskState.createPhotoTaskPayload.skuIds =
      photoTaskState.createPhotoTaskPayload.skuIds.filter((id) => id !== skuId);
  };

  onUnmounted(() => {
    state.items = [];
  });

  return {
    state,
    addSkuByBarcode,
    addSkuByIds,
    removeSkuById,
  };
};
