/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EventType {
    Unknown = 'UNKNOWN',
    PhotoRequestStatusUpdate = 'PHOTO_REQUEST_STATUS_UPDATE',
    PhotoRequestProducerUpdate = 'PHOTO_REQUEST_PRODUCER_UPDATE',
    PhotoRequestDetailedPhotographerUpdate = 'PHOTO_REQUEST_DETAILED_PHOTOGRAPHER_UPDATE',
    PhotoRequestModelPhotographerUpdate = 'PHOTO_REQUEST_MODEL_PHOTOGRAPHER_UPDATE',
    PhotoRequestPhotographerUpdate = 'PHOTO_REQUEST_PHOTOGRAPHER_UPDATE',
    PhotoRequestRetoucherUpdate = 'PHOTO_REQUEST_RETOUCHER_UPDATE',
    PhotoRequestPhotoModelUpdate = 'PHOTO_REQUEST_PHOTO_MODEL_UPDATE',
    PhotoRequestPhotoLinkUpdate = 'PHOTO_REQUEST_PHOTO_LINK_UPDATE',
    PhotoRequestAssistantAddition = 'PHOTO_REQUEST_ASSISTANT_ADDITION',
    PhotoRequestAssistantRemoval = 'PHOTO_REQUEST_ASSISTANT_REMOVAL',
    PhotoRequestVisagisteAddition = 'PHOTO_REQUEST_VISAGISTE_ADDITION',
    PhotoRequestVisagisteRemoval = 'PHOTO_REQUEST_VISAGISTE_REMOVAL',
    PhotoRequestIssuingEditorAddition = 'PHOTO_REQUEST_ISSUING_EDITOR_ADDITION',
    PhotoRequestIssuingEditorRemoval = 'PHOTO_REQUEST_ISSUING_EDITOR_REMOVAL',
    PhotoRequestIssuingEditorBeforeRetouchAddition = 'PHOTO_REQUEST_ISSUING_EDITOR_BEFORE_RETOUCH_ADDITION',
    PhotoRequestIssuingEditorBeforeRetouchRemoval = 'PHOTO_REQUEST_ISSUING_EDITOR_BEFORE_RETOUCH_REMOVAL',
    SkuAddedToInvoice = 'SKU_ADDED_TO_INVOICE',
    SkuAddedToPhotoRequest = 'SKU_ADDED_TO_PHOTO_REQUEST',
    SkuStatusStatusUpdate = 'SKU_STATUS_STATUS_UPDATE',
    SkuDeletedFromInvoice = 'SKU_DELETED_FROM_INVOICE',
    SkuDefected = 'SKU_DEFECTED',
    SkuDeletedFromPhotoRequest = 'SKU_DELETED_FROM_PHOTO_REQUEST',
    PhotoRequestPhotoLinkBeforeUpdate = 'PHOTO_REQUEST_PHOTO_LINK_BEFORE_UPDATE',
    PhotoRequestPhotoLinkAfterUpdate = 'PHOTO_REQUEST_PHOTO_LINK_AFTER_UPDATE',
    SkuDefectDeleted = 'SKU_DEFECT_DELETED',
    SkuDefectChangedReason = 'SKU_DEFECT_CHANGED_REASON',
    PhotoRequestItemReviewStatusUpdate = 'PHOTO_REQUEST_ITEM_REVIEW_STATUS_UPDATE',
    SkuReservationItemAdd = 'SKU_RESERVATION_ITEM_ADD',
    SkuReservationItemChangeStatus = 'SKU_RESERVATION_ITEM_CHANGE_STATUS',
    PhotoRequestCreatedFromAnother = 'PHOTO_REQUEST_CREATED_FROM_ANOTHER',
    PhotoRequestItemDefectCommentChanged = 'PHOTO_REQUEST_ITEM_DEFECT_COMMENT_CHANGED',
    PhotoRequestItemPaymentRateChanged = 'PHOTO_REQUEST_ITEM_PAYMENT_RATE_CHANGED',
    PhotoRequestChangeAutomatizationFlag = 'PHOTO_REQUEST_CHANGE_AUTOMATIZATION_FLAG'
}

export function EventTypeFromJSON(json: any): EventType {
    return EventTypeFromJSONTyped(json, false);
}

export function EventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventType {
    return json as EventType;
}

export function EventTypeToJSON(value?: EventType | null): any {
    return value as any;
}

