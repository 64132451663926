/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationResponse,
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';
import {
    ServiceListResponseAllOf,
    ServiceListResponseAllOfFromJSON,
    ServiceListResponseAllOfFromJSONTyped,
    ServiceListResponseAllOfToJSON,
} from './ServiceListResponseAllOf';
import {
    ServiceResponse,
    ServiceResponseFromJSON,
    ServiceResponseFromJSONTyped,
    ServiceResponseToJSON,
} from './ServiceResponse';

/**
 * 
 * @export
 * @interface ServiceListResponse
 */
export interface ServiceListResponse {
    /**
     * количество элементов на одной странице
     * @type {number}
     * @memberof ServiceListResponse
     */
    pageSize: number;
    /**
     * номер страницы
     * @type {number}
     * @memberof ServiceListResponse
     */
    page: number;
    /**
     * общее количество страниц
     * @type {number}
     * @memberof ServiceListResponse
     */
    totalPages: number;
    /**
     * общее количество элементов
     * @type {number}
     * @memberof ServiceListResponse
     */
    totalItems: number;
    /**
     * Список услуг
     * @type {Array<ServiceResponse>}
     * @memberof ServiceListResponse
     */
    items: Array<ServiceResponse>;
}

export function ServiceListResponseFromJSON(json: any): ServiceListResponse {
    return ServiceListResponseFromJSONTyped(json, false);
}

export function ServiceListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalPages': json['totalPages'],
        'totalItems': json['totalItems'],
        'items': ((json['items'] as Array<any>).map(ServiceResponseFromJSON)),
    };
}

export function ServiceListResponseToJSON(value?: ServiceListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'page': value.page,
        'totalPages': value.totalPages,
        'totalItems': value.totalItems,
        'items': ((value.items as Array<any>).map(ServiceResponseToJSON)),
    };
}

