import { HTTPError } from 'ky';
import { reactive, ref, watch } from 'vue';

import { EAccountRole, wmsApi } from '@/api';
import { clone } from '@/utils/collection';

import {
  IChangeWmsAccount,
  ICreateWmsAccount,
  IWmsAccount,
  IWmsRole,
} from '../types';

interface IState {
  loading: boolean;
  users: IWmsAccount[];
  roles: IWmsRole[];
  error?: boolean;
}

const initialState: IState = {
  loading: true,
  users: [],
  roles: [],
  error: false,
};

const rolesList = [
  EAccountRole.PHOTOSTUDIO_ADMIN,
  EAccountRole.PHOTOSTUDIO_PHOTOGRAPHER,
  EAccountRole.PHOTOSTUDIO_PRODUCER,
  EAccountRole.PHOTOSTUDIO_RETOUCHER_SENIOR,
  EAccountRole.PHOTOSTUDIO_RETOUCHER,
  EAccountRole.PHOTOSTUDIO_VISAGISTE,
  EAccountRole.PHOTOSTUDIO_ASSISTANT,
  EAccountRole.PHOTOSTUDIO_TURNOVER_WORKER,
  EAccountRole.PHOTOSTUDIO_TURNOVER_WORKER_SENIOR,
  EAccountRole.PHOTOSTUDIO_SELLER_SUPPORT,
  EAccountRole.PHOTOSTUDIO_SUPPORT_SPECIALIST,
  EAccountRole.ISSUING_EDITOR,
  EAccountRole.ISSUING_EDITOR_BEFORE_RETOUCH,
  EAccountRole.DEVELOPER,
  EAccountRole.OBSERVER,
];

const convertDateToTimeStamp = (date: string): number => {
  return Math.trunc(Number(new Date(date)) / 1000);
};

export function useAdministrationApi() {
  const state = reactive<IState>(clone(initialState));

  const filters = reactive({
    fullname: '',
    phone: '',
    roles: [] as string[],
  });

  const filteredUsers = ref<IWmsAccount[]>([]);

  const useFilters = () => {
    filteredUsers.value = state.users.filter((user) => {
      if (
        (!filters.fullname ||
          user.fullName
            ?.toLowerCase()
            .includes(filters.fullname?.toLowerCase())) &&
        (!filters.phone ||
          user.phone?.toLowerCase().includes(filters.phone?.toLowerCase())) &&
        (!filters.roles.length || filters.roles.includes(user.role.name))
      ) {
        return true;
      } else {
        return false;
      }
    });
    useSort();
  };

  watch(filters, () => {
    useFilters();
  });

  const sortDirection = ref('');

  const useSort = () => {
    if (sortDirection.value === 'asc') {
      filteredUsers.value.sort((a, b) =>
        (a.createdTimestamp || 0) > (b.createdTimestamp || 0) ? 1 : -1,
      );
    } else if (sortDirection.value === 'desc') {
      filteredUsers.value.sort((a, b) =>
        (a.createdTimestamp || 0) < (b.createdTimestamp || 0) ? 1 : -1,
      );
    }
  };

  watch(sortDirection, () => {
    if (!sortDirection.value) {
      filteredUsers.value = clone(state.users);
      useFilters();
    }

    useSort();
  });

  const handleGetUsersResponse = (response: IWmsAccount[]) => {
    state.users = response.map((user) => {
      return {
        ...user,
        fullName:
          `${user.lastName} ${user.firstName}` +
          (user.patronymic ? ` ${user.patronymic}` : ''),
        createdTimestamp: convertDateToTimeStamp(user.dateCreated || ''),
      };
    });

    filteredUsers.value = clone(state.users);
    sortDirection.value = 'desc';
  };

  const getUsers = async () => {
    try {
      state.loading = true;
      state.error = false;

      const users = (await wmsApi
        .get(`account/account/all?roles=${rolesList.join(',')}`)
        .json()) as IWmsAccount[];

      handleGetUsersResponse(users);

      const allRoles = (await wmsApi
        .get('account/role/all')
        .json()) as IWmsRole[];

      state.roles = allRoles
        .filter((role) => rolesList.includes(role.name as EAccountRole))
        .sort((a, b) => a.localizedName.localeCompare(b.localizedName));

      return users;
    } catch {
      state.error = true;
    } finally {
      state.loading = false;
    }
  };

  const createUser = async (data: ICreateWmsAccount) => {
    try {
      const user = (await wmsApi
        .post('account/account/create', {
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .json()) as unknown as IWmsAccount;

      (user.fullName =
        `${user.lastName} ${user.firstName}` +
        (user.patronymic ? ` ${user.patronymic}` : '')),
        (user.createdTimestamp = convertDateToTimeStamp(
          user.dateCreated || '',
        )),
        state.users.unshift(user);
      filteredUsers.value.unshift(user);
    } catch (err: any) {
      throw await handleError(err);
    }
  };

  const editUser = async (data: IChangeWmsAccount) => {
    try {
      const response = await wmsApi.post('account/account/update', {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response) {
        const user = state.users.find((user) => user.phone === data.phone);

        const filteredUser = filteredUsers.value.find(
          (user) => user.phone === data.phone,
        );

        const newRole = state.roles.find((role) => role.name === data.role);

        if (user) {
          if (user.role.name !== data.role) {
            user.role = newRole as IWmsRole;
          }

          user.phone = data.newPhone || data.phone;
          user.firstName = data.firstName;
          user.lastName = data.lastName;
          user.patronymic = data.patronymic;
          user.fullName =
            `${user.lastName} ${user.firstName}` +
            (user.patronymic ? ` ${user.patronymic}` : '');
        }

        if (filteredUser) {
          filteredUser.phone = data.newPhone || data.phone;
          filteredUser.firstName = data.firstName;
          filteredUser.lastName = data.lastName;
          filteredUser.patronymic = data.patronymic;

          if (filteredUser.role.name !== data.role) {
            filteredUser.role = newRole as IWmsRole;
          }

          filteredUser.fullName =
            `${filteredUser.lastName} ${filteredUser.firstName}` +
            (filteredUser.patronymic ? ` ${filteredUser.patronymic}` : '');
        }
      }
    } catch (err: any) {
      throw await handleError(err);
    }
  };

  const deleteUser = async (phone: string) => {
    try {
      const response = await wmsApi.post('account/account/delete', {
        body: JSON.stringify({
          accountName: phone,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response) {
        state.users = state.users.filter((user) => user.phone !== phone);
        filteredUsers.value = filteredUsers.value.filter(
          (user) => user.phone !== phone,
        );
      }
    } catch (err: any) {
      throw await handleError(err);
    }
  };

  const restoreUser = async (phone: string) => {
    try {
      await wmsApi.post('account/account/restore', {
        body: JSON.stringify({
          accountName: phone,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (err: any) {
      throw await handleError(err);
    }
  };

  const handleError = async (err: HTTPError) => {
    const res = await err?.response?.json();

    return res?.localizedMessage;
  };

  const resetState = () => {
    state.loading = initialState.loading;
    state.users = clone(initialState.users);
    state.roles = clone(initialState.roles);
    state.error = initialState.error;
  };

  return {
    state,
    getUsers,
    createUser,
    editUser,
    deleteUser,
    restoreUser,
    resetState,
    filters,
    filteredUsers,
    sortDirection,
    convertDateToTimeStamp,
  };
}
