<template>
  <IsInViewWrapper
    ref="container"
    class="container"
    :style="{
      height: data.items.length || error ? undefined : '100%',
      overflow: !data.items.length ? 'hidden' : 'auto',
    }"
  >
    <UiTable
      :columns="columns"
      :data-source="
        isLoading && !data.items.length ? Array(20).fill(null) : data.items
      "
      :loading="isLoading"
      :empty-text="$t('phototasksPage.table.emptyText')"
      :custom-row="() => ({ 'data-test-id': 'row__table' })"
      :custom-header-row="() => ({ 'data-test-id': 'table__header' })"
      bordered
    >
      <template #bodyCell="{ column, record }">
        <template v-if="isLoading && !data.items.length">
          <template v-if="column.key === 'id'">
            <div class="id-column">
              <Skeleton :paragraph="false" active />
            </div>
          </template>

          <template v-if="column.key === 'skuIds'">
            <Skeleton
              :paragraph="false"
              style="width: 80px; margin-left: auto"
              active
            />
          </template>

          <template v-else-if="column.key === 'sellerContact'">
            <Skeleton :paragraph="false" style="width: 120px" active />
          </template>

          <template v-else-if="column.key === 'dueDate'">
            <Skeleton :paragraph="false" style="width: 110px" active />
          </template>

          <template v-else-if="column.key === 'requirements'">
            <Skeleton :paragraph="false" style="width: 110px" active />
          </template>
          <template v-else-if="column.key === 'infographicsOnly'">
            <Skeleton :paragraph="false" style="width: 110px" active />
          </template>
        </template>

        <template v-else>
          <template v-if="column.key === 'id'">
            <UiButton
              type="link"
              class="link"
              data-test-id="button__number_phototasks"
            >
              {{ record?.id }}
            </UiButton>
          </template>

          <template v-if="column.key === 'photoRequestId'">
            <UiButton v-if="record?.photoRequestId" type="link" class="link">
              {{ record?.photoRequestId }}
            </UiButton>
            <span v-else>Не выбран</span>
          </template>

          <template v-else-if="column.key === 'skuIds'">
            {{ record?.skuIds?.length }}
          </template>

          <template v-else-if="column.key === 'sellerContact'">
            {{ record?.sellerContact }}
          </template>

          <template v-else-if="column.key === 'dueDate'">
            <Date :value="record?.dueDate" />
          </template>

          <template v-else-if="column.key === 'requirements'">
            {{ record?.requirements }}
          </template>

          <template v-else-if="column.key === 'infographicsOnly'">
            {{ record?.infographicsOnly ? 'Да' : 'Нет' }}
          </template>
        </template>
      </template>
    </UiTable>
    <template v-if="data.items.length && hasNextPage">
      <InfiniteLoader :has-more="hasNextPage" @enter="fetchNextPage()" />

      <LinearLoader class="loader" />
    </template>
  </IsInViewWrapper>
</template>

<script setup lang="ts">
import { Skeleton, TableColumnType } from 'ant-design-vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { PhotoTaskStatus } from '@/api';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import IsInViewWrapper from '@/components/IsInViewWrapper.vue';
import { usePreserveScroll } from '@/composables/usePreserveScroll';
import { EModalTypes, useRoutedModal } from '@/features/Modals';
import { usePhototasks } from '@/features/Phototasks';
import { AppRoutes } from '@/router';
import { Date, LinearLoader, UiButton, UiTable } from '@/ui';

interface Props {
  status: PhotoTaskStatus;
}

const props = defineProps<Props>();
const { t } = useI18n();
const router = useRouter();
const [openPhototaskModal] = useRoutedModal(EModalTypes.PHOTOTASK);

const { data, error, isLoading, hasNextPage, fetchNextPage, refetch } =
  usePhototasks(props.status);

const columns = computed<TableColumnType[]>(() => [
  {
    dataIndex: 'id',
    key: 'id',
    title: t('phototasksPage.table.columns.id'),
    customCell: (record) => {
      return {
        onClick: () => openPhototaskModal({ id: record.id }),
      };
    },
  },
  {
    dataIndex: 'photoRequestId',
    key: 'photoRequestId',
    title: t('phototasksPage.table.columns.photoRequestId'),
    customCell: (record) => {
      return {
        onClick: () => {
          if (!record.photoRequestId) {
            return;
          }

          const routeData = router.resolve({
            name: AppRoutes.requests.name,
            params: { id: record.photoRequestId },
          });

          window.open(routeData.href, '_blank');
        },
      };
    },
  },
  {
    dataIndex: 'skuIds',
    key: 'skuIds',
    title: t('phototasksPage.table.columns.itemCount'),
  },
  {
    dataIndex: 'sellerContact',
    key: 'sellerContact',
    title: t('phototasksPage.table.columns.sellerContact'),
  },
  {
    dataIndex: 'dueDate',
    key: 'dueDate',
    title: t('phototasksPage.table.columns.dueDate'),
  },
  {
    dataIndex: 'requirements',
    key: 'requirements',
    title: t('phototasksPage.table.columns.requirements'),
  },
  {
    dataIndex: 'infographicsOnly',
    key: 'infographicsOnly',
    title: t('phototasksPage.table.columns.infographics'),
  },
]);

const container = ref<HTMLElement>();

usePreserveScroll(container, true);
</script>

<style lang="stylus" scoped>
.container
  border 1px solid #f0f0f0
  border-radius 5px

  :deep()
    .ant-table-thead .ant-table-cell
      background-color gray-2

    .ant-skeleton-title
      margin 0

    .ant-table-container
      border 0 !important

    .ant-table-cell
      height 58px

      &:last-child
        border-right 0 !important

    table
      border-top 0 !important

    tr:last-child td
      border-bottom 0 !important

    td:first-child
      cursor pointer

.id-column
  display grid
  grid-template-columns 25% auto
  place-items center start
  gap 10px

  .shooting-types
    display flex
    align-items center
    flex-wrap wrap
    gap 4px

.link
  padding 0

.returned-from-defect
  display flex
  align-items center
  justify-content center
  background rgba(255, 229, 143, 1)
  height 26px
  width 26px
  border-radius 20px

.loader
  width calc(100% - 40px)
  display flex
  align-items center
</style>
