<template>
  <UiTable
    :columns="columns"
    :data-source="state.items"
    :empty-text="$t('phototasksPage.table.emptyText')"
    :custom-row="() => ({ 'data-test-id': 'row__table', class: 'hover-row' })"
    :custom-header-row="() => ({ 'data-test-id': 'table__header' })"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'photo'">
        <image-cell :url="getPhotoUrl(record)" data-intro="sku-image" />
      </template>

      <template v-else-if="column.key === 'description'">
        {{ getLang(record.description) }}
      </template>

      <template v-else-if="column.key === 'sku'">
        <span class="whitespace-nowrap">
          {{ record?.title }}
        </span>
      </template>

      <template v-else-if="column.key === 'barcode'">
        <ATypographyText class="whitespace-nowrap" copyable>
          {{ record.barcode }}
        </ATypographyText>
      </template>

      <template v-else-if="column.key === 'action'">
        <UiButton
          class="delete-button"
          danger
          :icon="h(DeleteOutlined)"
          data-test-id="delete-button"
          @click="removeSkuById(record.id)"
        />
      </template>
    </template>
  </UiTable>
</template>

<script setup lang="ts">
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons-vue';
import { TableColumnType } from 'ant-design-vue';
import { computed, h } from 'vue';
import { useI18n } from 'vue-i18n';

import { Image, SkuListEntry } from '@/api';
import ImageCell from '@/components/table/cells/image-cell.vue';
import { usePhotoTaskSku } from '@/features/Modals/photoTask/composables/usePhotoTaskSku';
import { PhotoTaskModalMode } from '@/features/Modals/photoTask/data';
import { UiButton, UiTable } from '@/ui';
import { productImageSrc } from '@/utils/imageUrl';
import { getLang } from '@/utils/lang';

interface Props {
  mode: PhotoTaskModalMode;
}

const props = defineProps<Props>();
const { t } = useI18n();
const { state, removeSkuById } = usePhotoTaskSku();

const columns = computed<TableColumnType[]>(() => {
  // Базовые столбцы
  const baseColumns: TableColumnType[] = [
    {
      dataIndex: 'photo',
      key: 'photo',
      title: t('photo'),
      align: 'center',
      width: '100px',
    },
    {
      dataIndex: 'description',
      key: 'description',
      title: t('modal.phototask.table.description'),
    },
    {
      dataIndex: 'sku',
      key: 'sku',
      title: 'SKU',
    },
    {
      dataIndex: 'barcode',
      key: 'barcode',
      title: t('barcode'),
    },
  ];

  // Добавляем колонку действий, если mode === Create
  if (props.mode === PhotoTaskModalMode.Create) {
    return [
      ...baseColumns,
      {
        dataIndex: 'action',
        key: 'action',
        title: t('action'),
        align: 'center',
        width: '100px',
      },
    ];
  }

  return baseColumns;
});

const getPhotoUrl = (sku: SkuListEntry) => {
  if (!sku.images[0] || !sku.images[0].key) return sku.photoURL;

  const productImage: Image | undefined = sku.images.toSorted((a, b) => {
    return a.ordering - b.ordering;
  })[0];

  return productImageSrc(productImage?.key, productImage?.urlPrefix);
};
</script>

<style lang="stylus" scoped>

.hover-row {
  cursor pointer
}

.hover-row:hover .delete-button {
  visibility: visible; /* Показываем кнопку при наведении */
}

.delete-button {
  visibility: hidden; /* Скрываем кнопку по умолчанию */
}
</style>
