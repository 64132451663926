<template>
  <UiTag
    class="status"
    :class="{ active }"
    :data-test-id="`button__${status.toLocaleLowerCase()}`"
  >
    {{ $t(`phototasksStatus.${status}`) }}
    <Skeleton
      v-if="count.isLoading"
      :paragraph="false"
      style="width: 30px"
      active
    />
    <span class="count" data-test-id="count__element">
      {{ count.value }}
    </span>
  </UiTag>
</template>

<script setup lang="ts">
import { Skeleton } from 'ant-design-vue';

import { PhotoTaskStatus } from '@/api';
import { usePhototasksCount } from '@/features/Phototasks';
import { UiTag } from '@/ui';

interface Props {
  status: PhotoTaskStatus;
  active: boolean;
}

const props = defineProps<Props>();
const count = usePhototasksCount(props.status);
</script>

<style lang="stylus" scoped>
.status
  display flex
  align-items center
  gap 8px
  padding 4px 12px
  border-radius 100px
  line-height 1.6
  background-color #E4E4E4
  color gray-10
  margin-right 0
  cursor pointer

  &.active
    color gray-1
    background-color blue-6

    .count
      color blue-6

.count
  padding 0 8px
  border-radius 100px
  font-size 12px
  background-color gray-4
</style>
