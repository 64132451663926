<template>
  <div class="header">
    <ModalNav :items="locationPath" />

    <div class="buttons-block items-center">
      <slot />

      <UiButton
        variant="icon"
        data-test-id="button__close-request-modal"
        @click="$emit('close')"
      >
        <icon-ui name="navigation/dissmiss_line" />
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { IconUI, ModalNav, UiButton } from '@/ui';

interface Props {
  id?: number;
  customLocationPath?: { label: string; path?: string }[];
}

const props = defineProps<Props>();

defineEmits(['close']);

const { t } = useI18n();

const defaultLocationPath = [
  { label: t('photostudio'), path: '/' },
  { label: t('requests'), path: '/requests' },
  { label: t('modal.request.title', { id: props.id }) },
];

const locationPath = computed(() => {
  return props.customLocationPath || defaultLocationPath; // Используем переданный пропс или значение по умолчанию
});
</script>

<style scoped lang="stylus">


.header
  display flex
  align-items center
  justify-content space-between
  gap 30px
  height 50px
  padding-left 32px
  padding-right 36px
  flex-shrink 0

.buttons-block
  display flex
  gap 10px
  fill Gray(DK8)
</style>
