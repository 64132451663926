<template>
  <div class="login-form flex justify-center items-center h-full">
    <UiCard class="w-92">
      <img :src="getLogo()" class="block mx-auto mb-10 w-24" />
      <UiAlert
        v-if="isCapsLockOn"
        class="mb-3"
        type="warning"
        show-icon
        :message="$t('login.capslock_on')"
      />
      <UiAlert
        v-else-if="errorMessage"
        class="mb-3"
        type="error"
        show-icon
        :message="$t(`login.${errorMessage}`)"
      />
      <UiForm
        :model="formState"
        :label-col="{ span: 24 }"
        autocomplete="off"
        hide-required-mark
        @finish="onFinish"
      >
        <UiFormItem
          :label="$t('login.username')"
          name="username"
          :rules="[
            {
              required: true,
              min: phoneMask.length,
              max: phoneMask.length,
              message: $t('login.phone_error'),
            },
          ]"
        >
          <UiInput
            v-model:value="formState.username"
            v-maska="phoneMask"
            :maxlength="phoneMask.length"
            :placeholder="$t('login.phone_placeholder')"
          />
        </UiFormItem>
        <UiFormItem
          :label="$t('login.password')"
          name="password"
          :rules="[{ required: true, message: $t('login.password_error') }]"
        >
          <UiInputPassword
            v-model:value="formState.password"
            @keyup="handlePasswordKeyUp"
          />
        </UiFormItem>
        <UiFormItem class="mb-0 mt-8">
          <!-- TODO Удалить контейнер -->
          <div
            ref="loginBtnWrapper"
            :style="{
              position: 'absolute',
              width: '100%',
              top: 0,
              left: 0,
              transition: 'all 0.07s linear 0s',
              zIndex: '10',
            }"
          >
            <UiButton
              class="w-full"
              type="primary"
              html-type="submit"
              :loading="loading"
            >
              {{ $t('login.button') }}
            </UiButton>
          </div>
        </UiFormItem>
      </UiForm>
    </UiCard>
  </div>
</template>

<script setup lang="ts">
import { TimeoutError } from 'ky';
import { onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { COMPANY } from '@/config';
import { isUnauthorizedError, useAuthMethods } from '@/features/Auth';
import { AppRoutes } from '@/router';
import {
  UiAlert,
  UiButton,
  UiCard,
  UiForm,
  UiFormItem,
  UiInput,
  UiInputPassword,
} from '@/ui';
import { phoneMask, unmaskPhone } from '@/utils/phone';

import { initRunningButton } from './joke';

const getLogo = () => {
  const logoSrcName = `login-logo_${COMPANY}.svg`;

  return new URL(`/src/assets/${logoSrcName}`, import.meta.url).href;
};

interface FormState {
  username: string;
  password: string;
}

const formState = reactive<FormState>({
  username: '',
  password: '',
});

const loginBtnWrapper = ref();
const loading = ref(false);
const errorMessage = ref('');
const { login, logOut, rehydrateAccount } = useAuthMethods();
const router = useRouter();
const isCapsLockOn = ref<boolean>(false);

const handlePasswordKeyUp = (event: KeyboardEvent) => {
  isCapsLockOn.value = event.getModifierState?.('CapsLock');
};

const onFinish = async (values: FormState): Promise<void> => {
  loading.value = true;

  await login(unmaskPhone(values.username), values.password)
    .then(async (account) => {
      if (!account) return;

      await rehydrateAccount();

      router.push({ name: AppRoutes.requests.name });
    })
    .catch((err) => {
      if (isUnauthorizedError(err)) {
        router.push({ name: AppRoutes.notAuthorized.name });
      } else if (window.navigator.onLine === false) {
        errorMessage.value = 'error_login_no_internet';
      } else if (
        err instanceof TimeoutError ||
        err instanceof TypeError ||
        err?.response?.status > 400
      ) {
        errorMessage.value = 'error_login_cant_connect_to_server';
      } else {
        errorMessage.value = 'error_login_invalid';
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  initRunningButton(loginBtnWrapper.value);
});
</script>
<style lang="stylus" scoped>
.login-form
  :deep(.ant-card)
    .ant-form-item-label
      padding 0
</style>
