/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePhotoTaskServices
 */
export interface UpdatePhotoTaskServices {
    /**
     * Список идентификаторов услуг
     * @type {Array<number>}
     * @memberof UpdatePhotoTaskServices
     */
    serviceIds: Array<number>;
}

export function UpdatePhotoTaskServicesFromJSON(json: any): UpdatePhotoTaskServices {
    return UpdatePhotoTaskServicesFromJSONTyped(json, false);
}

export function UpdatePhotoTaskServicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePhotoTaskServices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceIds': json['service_ids'],
    };
}

export function UpdatePhotoTaskServicesToJSON(value?: UpdatePhotoTaskServices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service_ids': value.serviceIds,
    };
}

