import { useInfiniteQuery } from '@tanstack/vue-query';
import debounce from 'lodash.debounce';
import { computed, ref } from 'vue';

import { sellersApi } from '@/api';
import { DEBOUNCE_DELAY } from '@/features/Common';

export const useSellers = (pageSize: number = 50) => {
  const searchQuery = ref<string | undefined>();

  const debouncedSearch = debounce((query: string) => {
    searchQuery.value = query;
  }, DEBOUNCE_DELAY); // 300 мс задержка перед выполнением

  const { data, fetchNextPage, isFetching } = useInfiniteQuery({
    queryKey: computed(() => ['sellers', searchQuery.value]),
    queryFn: async ({ pageParam = 0 }) =>
      await sellersApi.sellersListByIDs({
        page: pageParam,
        size: pageSize,
        q: searchQuery.value,
      }),
    // Определяем следующую страницу на основе размера возвращённых данных
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.items.length === pageSize ? allPages.length : undefined;
    },
    keepPreviousData: true,
  });

  const options = computed(
    () =>
      data.value?.pages.flatMap((page) =>
        page.items.map((seller) => {
          // Проверяем наличие ФИО
          const fullName =
            [seller.lastName, seller.firstName, seller.patronymic]
              .filter(Boolean) // Убираем undefined или null
              .join(' ') || null;

          // Если ФИО нет, проверяем email, затем phone, затем id
          const label = fullName || seller.email || seller.phone || seller.id;

          return {
            label, // Формируем метку
            value: seller.id, // Значение остается id
          };
        }),
      ) ?? [],
  );

  const search = (query: string) => {
    debouncedSearch(query);
  };

  const loadMore = async () => {
    const hasNextPage = data.value?.pages.at(-1)?.items.length === pageSize;

    if (hasNextPage && !isFetching.value) {
      await fetchNextPage();
    }
  };

  return {
    options,
    search,
    loadMore,
    isFetching,
  };
};
