import { useInfiniteQuery } from '@tanstack/vue-query';
import { computed, ComputedRef, reactive } from 'vue';

import {
  photoTaskApi,
  PhotoTaskListRequest,
  PhotoTaskListResponse,
  PhotoTaskStatus,
} from '@/api';
import { PAGE_SIZE } from '@/features/Common';

const initialData: PhotoTaskListResponse = {
  pageSize: 0,
  page: 0,
  totalPages: 0,
  totalItems: 0,
  items: [],
};

const fetchPhotoTasks = async (
  params: PhotoTaskListRequest,
  { signal }: { signal?: AbortSignal } = {},
): Promise<PhotoTaskListResponse> => {
  return await photoTaskApi.photoTaskList(
    { page: params.page, size: params.size, status: params.status },
    { signal },
  );
};

export const usePhototasks = (status: PhotoTaskStatus) => {
  const queryInfo = useInfiniteQuery({
    queryKey: ['phototasks', status],
    queryFn: async ({ signal, pageParam = 0 }) => {
      const { items, totalItems, totalPages } = await fetchPhotoTasks(
        {
          status: status,
          page: pageParam,
          size: PAGE_SIZE,
        },
        { signal },
      );

      return {
        items,
        totalItems,
        nextPage: pageParam < totalPages - 1 ? pageParam + 1 : undefined,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    select: (data) => ({
      ...data,
      items: data.pages.flatMap((p) => p.items),
      totalItems: data.pages.at(-1)?.totalItems ?? 0,
    }),
  });

  const data = computed(() => queryInfo.data.value ?? initialData);

  return {
    ...queryInfo,
    data: data as ComputedRef<PhotoTaskListResponse>,
  };
};

export const usePhototasksCount = (status: PhotoTaskStatus) => {
  const { data, isLoading } = usePhototasks(status);

  return computed(() => {
    if (isLoading.value)
      return {
        isLoading: true,
        value: 0,
      };

    return {
      isLoading: false,
      value: data.value.totalItems,
    };
  });
};

export const usePhototasksCounts = (statuses: PhotoTaskStatus[]) =>
  reactive(
    Object.fromEntries(
      statuses.map((s) => [s, usePhototasksCount(s)]),
    ) as Record<
      PhotoTaskStatus,
      ComputedRef<{
        isLoading: boolean;
        value: number;
      }>
    >,
  );
