/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BulkDeleteInvoiceItemsRequest,
    BulkDeleteInvoiceItemsRequestFromJSON,
    BulkDeleteInvoiceItemsRequestToJSON,
    ChangeInvoiceDriver,
    ChangeInvoiceDriverFromJSON,
    ChangeInvoiceDriverToJSON,
    ChangeInvoiceStatus,
    ChangeInvoiceStatusFromJSON,
    ChangeInvoiceStatusToJSON,
    Invoice,
    InvoiceFromJSON,
    InvoiceToJSON,
    InvoiceItem,
    InvoiceItemFromJSON,
    InvoiceItemToJSON,
    InvoiceItemListResponse,
    InvoiceItemListResponseFromJSON,
    InvoiceItemListResponseToJSON,
    InvoiceItemTemplate,
    InvoiceItemTemplateFromJSON,
    InvoiceItemTemplateToJSON,
    InvoiceListResponse,
    InvoiceListResponseFromJSON,
    InvoiceListResponseToJSON,
    InvoiceNotAddedItemListResponse,
    InvoiceNotAddedItemListResponseFromJSON,
    InvoiceNotAddedItemListResponseToJSON,
    InvoiceStatus,
    InvoiceStatusFromJSON,
    InvoiceStatusToJSON,
    InvoiceTemplate,
    InvoiceTemplateFromJSON,
    InvoiceTemplateToJSON,
    InvoiceType,
    InvoiceTypeFromJSON,
    InvoiceTypeToJSON,
    SetProductAmount,
    SetProductAmountFromJSON,
    SetProductAmountToJSON,
    SortInvoices,
    SortInvoicesFromJSON,
    SortInvoicesToJSON,
} from '../models';

export interface AddItemToInvoiceRequest {
    invoiceID: number;
    invoiceItemTemplate?: InvoiceItemTemplate;
}

export interface BulkDdeleteInvoiceItemsRequest {
    invoiceID: number;
    bulkDeleteInvoiceItemsRequest?: BulkDeleteInvoiceItemsRequest;
}

export interface ChangeInvoiceDriverRequest {
    invoiceID: number;
    changeInvoiceDriver?: ChangeInvoiceDriver;
}

export interface ChangeInvoiceStatusRequest {
    invoiceID: number;
    changeInvoiceStatus?: ChangeInvoiceStatus;
}

export interface CreateInvoiceRequest {
    invoiceTemplate?: InvoiceTemplate;
}

export interface DeleteInvoiceRequest {
    invoiceID: number;
}

export interface DeleteInvoiceItemRequest {
    itemID: number;
}

export interface GetInvoiceByIDRequest {
    invoiceID: number;
}

export interface GetInvoiceItemsRequest {
    invoiceID: number;
    page: number;
    size: number;
    skuID?: number;
    differentAmounts?: boolean;
    q?: string;
}

export interface GetInvoiceNotAddedItemsRequest {
    invoiceID: number;
    page: number;
    size: number;
}

export interface InvoiceListRequest {
    page: number;
    size: number;
    boxId?: number;
    status?: Array<InvoiceStatus>;
    type?: InvoiceType;
    ids?: Array<number>;
    sort?: Array<SortInvoices>;
}

export interface SetProductAmountRequest {
    itemID: number;
    setProductAmount?: SetProductAmount;
}

export interface SetProductAmountV2Request {
    itemID: number;
    setProductAmount?: SetProductAmount;
}

/**
 * 
 */
export class InvoiceApi extends runtime.BaseAPI {

    /**
     * Добавить элемент в накладную
     */
    async addItemToInvoiceRaw(requestParameters: AddItemToInvoiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InvoiceItem>> {
        if (requestParameters.invoiceID === null || requestParameters.invoiceID === undefined) {
            throw new runtime.RequiredError('invoiceID','Required parameter requestParameters.invoiceID was null or undefined when calling addItemToInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices/{invoiceID}/items`.replace(`{${"invoiceID"}}`, encodeURIComponent(String(requestParameters.invoiceID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceItemTemplateToJSON(requestParameters.invoiceItemTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceItemFromJSON(jsonValue));
    }

    /**
     * Добавить элемент в накладную
     */
    async addItemToInvoice(requestParameters: AddItemToInvoiceRequest, initOverrides?: RequestInit): Promise<InvoiceItem> {
        const response = await this.addItemToInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Массовое удаление товара из накладной
     */
    async bulkDdeleteInvoiceItemsRaw(requestParameters: BulkDdeleteInvoiceItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.invoiceID === null || requestParameters.invoiceID === undefined) {
            throw new runtime.RequiredError('invoiceID','Required parameter requestParameters.invoiceID was null or undefined when calling bulkDdeleteInvoiceItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices/{invoiceID}/items`.replace(`{${"invoiceID"}}`, encodeURIComponent(String(requestParameters.invoiceID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: BulkDeleteInvoiceItemsRequestToJSON(requestParameters.bulkDeleteInvoiceItemsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Массовое удаление товара из накладной
     */
    async bulkDdeleteInvoiceItems(requestParameters: BulkDdeleteInvoiceItemsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.bulkDdeleteInvoiceItemsRaw(requestParameters, initOverrides);
    }

    /**
     * Изменить водителя накладной
     */
    async changeInvoiceDriverRaw(requestParameters: ChangeInvoiceDriverRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.invoiceID === null || requestParameters.invoiceID === undefined) {
            throw new runtime.RequiredError('invoiceID','Required parameter requestParameters.invoiceID was null or undefined when calling changeInvoiceDriver.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices/{invoiceID}/driver`.replace(`{${"invoiceID"}}`, encodeURIComponent(String(requestParameters.invoiceID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeInvoiceDriverToJSON(requestParameters.changeInvoiceDriver),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Изменить водителя накладной
     */
    async changeInvoiceDriver(requestParameters: ChangeInvoiceDriverRequest, initOverrides?: RequestInit): Promise<Invoice> {
        const response = await this.changeInvoiceDriverRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Изменить статус накладной
     */
    async changeInvoiceStatusRaw(requestParameters: ChangeInvoiceStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.invoiceID === null || requestParameters.invoiceID === undefined) {
            throw new runtime.RequiredError('invoiceID','Required parameter requestParameters.invoiceID was null or undefined when calling changeInvoiceStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices/{invoiceID}/status`.replace(`{${"invoiceID"}}`, encodeURIComponent(String(requestParameters.invoiceID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeInvoiceStatusToJSON(requestParameters.changeInvoiceStatus),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Изменить статус накладной
     */
    async changeInvoiceStatus(requestParameters: ChangeInvoiceStatusRequest, initOverrides?: RequestInit): Promise<Invoice> {
        const response = await this.changeInvoiceStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new invoice
     */
    async createInvoiceRaw(requestParameters: CreateInvoiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Invoice>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvoiceTemplateToJSON(requestParameters.invoiceTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Create a new invoice
     */
    async createInvoice(requestParameters: CreateInvoiceRequest = {}, initOverrides?: RequestInit): Promise<Invoice> {
        const response = await this.createInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отменить создание накладной
     */
    async deleteInvoiceRaw(requestParameters: DeleteInvoiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.invoiceID === null || requestParameters.invoiceID === undefined) {
            throw new runtime.RequiredError('invoiceID','Required parameter requestParameters.invoiceID was null or undefined when calling deleteInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices/{invoiceID}`.replace(`{${"invoiceID"}}`, encodeURIComponent(String(requestParameters.invoiceID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отменить создание накладной
     */
    async deleteInvoice(requestParameters: DeleteInvoiceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteInvoiceRaw(requestParameters, initOverrides);
    }

    /**
     * Удалить элемент накладной
     */
    async deleteInvoiceItemRaw(requestParameters: DeleteInvoiceItemRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.itemID === null || requestParameters.itemID === undefined) {
            throw new runtime.RequiredError('itemID','Required parameter requestParameters.itemID was null or undefined when calling deleteInvoiceItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/invoices/items/{itemID}`.replace(`{${"itemID"}}`, encodeURIComponent(String(requestParameters.itemID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удалить элемент накладной
     */
    async deleteInvoiceItem(requestParameters: DeleteInvoiceItemRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteInvoiceItemRaw(requestParameters, initOverrides);
    }

    /**
     * Get invoice by its ID
     */
    async getInvoiceByIDRaw(requestParameters: GetInvoiceByIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Invoice>> {
        if (requestParameters.invoiceID === null || requestParameters.invoiceID === undefined) {
            throw new runtime.RequiredError('invoiceID','Required parameter requestParameters.invoiceID was null or undefined when calling getInvoiceByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices/{invoiceID}`.replace(`{${"invoiceID"}}`, encodeURIComponent(String(requestParameters.invoiceID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceFromJSON(jsonValue));
    }

    /**
     * Get invoice by its ID
     */
    async getInvoiceByID(requestParameters: GetInvoiceByIDRequest, initOverrides?: RequestInit): Promise<Invoice> {
        const response = await this.getInvoiceByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить элементы накладной
     */
    async getInvoiceItemsRaw(requestParameters: GetInvoiceItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InvoiceItemListResponse>> {
        if (requestParameters.invoiceID === null || requestParameters.invoiceID === undefined) {
            throw new runtime.RequiredError('invoiceID','Required parameter requestParameters.invoiceID was null or undefined when calling getInvoiceItems.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getInvoiceItems.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getInvoiceItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.skuID !== undefined) {
            queryParameters['skuID'] = requestParameters.skuID;
        }

        if (requestParameters.differentAmounts !== undefined) {
            queryParameters['differentAmounts'] = requestParameters.differentAmounts;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices/{invoiceID}/items`.replace(`{${"invoiceID"}}`, encodeURIComponent(String(requestParameters.invoiceID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceItemListResponseFromJSON(jsonValue));
    }

    /**
     * Получить элементы накладной
     */
    async getInvoiceItems(requestParameters: GetInvoiceItemsRequest, initOverrides?: RequestInit): Promise<InvoiceItemListResponse> {
        const response = await this.getInvoiceItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить не добавленные в накладную элементы из заявок
     */
    async getInvoiceNotAddedItemsRaw(requestParameters: GetInvoiceNotAddedItemsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InvoiceNotAddedItemListResponse>> {
        if (requestParameters.invoiceID === null || requestParameters.invoiceID === undefined) {
            throw new runtime.RequiredError('invoiceID','Required parameter requestParameters.invoiceID was null or undefined when calling getInvoiceNotAddedItems.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getInvoiceNotAddedItems.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getInvoiceNotAddedItems.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices/{invoiceID}/notAddedItems`.replace(`{${"invoiceID"}}`, encodeURIComponent(String(requestParameters.invoiceID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceNotAddedItemListResponseFromJSON(jsonValue));
    }

    /**
     * Получить не добавленные в накладную элементы из заявок
     */
    async getInvoiceNotAddedItems(requestParameters: GetInvoiceNotAddedItemsRequest, initOverrides?: RequestInit): Promise<InvoiceNotAddedItemListResponse> {
        const response = await this.getInvoiceNotAddedItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all invoices by specified filter
     */
    async invoiceListRaw(requestParameters: InvoiceListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InvoiceListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling invoiceList.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling invoiceList.');
        }

        const queryParameters: any = {};

        if (requestParameters.boxId !== undefined) {
            queryParameters['boxId'] = requestParameters.boxId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceListResponseFromJSON(jsonValue));
    }

    /**
     * Get all invoices by specified filter
     */
    async invoiceList(requestParameters: InvoiceListRequest, initOverrides?: RequestInit): Promise<InvoiceListResponse> {
        const response = await this.invoiceListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Задать конкретное количество товара товар
     */
    async setProductAmountRaw(requestParameters: SetProductAmountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InvoiceItem>> {
        if (requestParameters.itemID === null || requestParameters.itemID === undefined) {
            throw new runtime.RequiredError('itemID','Required parameter requestParameters.itemID was null or undefined when calling setProductAmount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/invoices/items/{itemID}/amount`.replace(`{${"itemID"}}`, encodeURIComponent(String(requestParameters.itemID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetProductAmountToJSON(requestParameters.setProductAmount),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceItemFromJSON(jsonValue));
    }

    /**
     * Задать конкретное количество товара товар
     */
    async setProductAmount(requestParameters: SetProductAmountRequest, initOverrides?: RequestInit): Promise<InvoiceItem> {
        const response = await this.setProductAmountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Задать конкретное количество товара товар
     */
    async setProductAmountV2Raw(requestParameters: SetProductAmountV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InvoiceItem>> {
        if (requestParameters.itemID === null || requestParameters.itemID === undefined) {
            throw new runtime.RequiredError('itemID','Required parameter requestParameters.itemID was null or undefined when calling setProductAmountV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v2/invoices/items/{itemID}/amount`.replace(`{${"itemID"}}`, encodeURIComponent(String(requestParameters.itemID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetProductAmountToJSON(requestParameters.setProductAmount),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceItemFromJSON(jsonValue));
    }

    /**
     * Задать конкретное количество товара товар
     */
    async setProductAmountV2(requestParameters: SetProductAmountV2Request, initOverrides?: RequestInit): Promise<InvoiceItem> {
        const response = await this.setProductAmountV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
