<template>
  <PageLayout>
    <template #header>
      <PageHeader
        :title="$t('products')"
        :subtitle="
          skus.loading ? ' ' : $t('pluralization.product', skus.totalCount)
        "
      >
        <BaseFilter
          :title="$t('status')"
          :configuration="skuStatusFilterConfiguration"
          :on-change="handleFilterChange"
          :is-selected="isFilterSelected"
        />
        <Search
          v-model="query"
          :with-barcode="true"
          class="search"
          :placeholder="$t('search product')"
          data-test-id="input__search"
        />
      </PageHeader>
    </template>

    <IsInViewWrapper
      ref="productContainer"
      class="container"
      :style="{
        height: '100%',
        overflow: 'auto',
      }"
    >
      <UiTable
        :columns="columns"
        :data-source="
          skus.loading && !skus.items.length ? Array(20).fill(null) : skus.items
        "
        :loading="skus.loading"
        :custom-row="customRow"
        :custom-header-row="() => ({ 'data-test-id': 'table__header' })"
        :empty-text="$t('productsPage.table.emptyText')"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="skus.loading && !skus.items.length">
            <SkeletonImage v-if="column.key === 'photo'" active size="small" />
            <Skeleton
              v-if="column.key === 'description'"
              :paragraph="false"
              active
            />
            <Skeleton
              v-if="column.key === 'status'"
              :paragraph="false"
              active
            />
            <Skeleton
              v-if="column.key === 'barcode'"
              :paragraph="false"
              active
            />
            <Skeleton v-if="column.key === 'sku'" :paragraph="false" active />
            <Skeleton
              v-if="column.key === 'onPhotoStudioCount'"
              :paragraph="false"
              active
            />
          </template>

          <template v-else>
            <UiImagePreview
              v-if="column.key === 'photo'"
              :current="0"
              :src="record.photoURL"
              :width="80"
              :height="80"
              hide-preview
              class="cursor-pointer"
            />

            <TextCell
              v-else-if="column.key === 'description'"
              class="cursor-pointer"
            >
              {{ getLang(record.description) }}
            </TextCell>

            <ChipCell
              v-else-if="column.key === 'status'"
              :color="skuStatusToColor[record.status]"
              :value="$t(`sku.${record.status}`)"
              class="cursor-pointer"
            />

            <TextCell
              v-else-if="column.key === 'barcode'"
              :copyable="record.barcode"
              class="cursor-pointer whitespace-nowrap"
            >
              {{ record.barcode }}
            </TextCell>

            <TextCell v-else-if="column.key === 'sku'" class="cursor-pointer">
              {{ record.title }}
            </TextCell>

            <TextCell
              v-else-if="column.key === 'onPhotoStudioCount'"
              class="cursor-pointer"
            >
              {{ record.onPhotoStudioCount }}
            </TextCell>
          </template>
        </template>
      </UiTable>

      <template v-if="skus.items.length && skus.hasMore">
        <InfiniteLoader :has-more="skus.hasMore" @enter="handleLoadMore" />

        <LinearLoader class="loader" />
      </template>
    </IsInViewWrapper>

    <ScannerEmulator v-if="isDevelopment" />
  </PageLayout>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, ref } from 'vue';
export default defineComponent({ name: 'products-page' });
</script>

<script setup lang="ts">
import { Skeleton, SkeletonImage, TableColumnType } from 'ant-design-vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import InfiniteLoader from '@/components/InfiniteLoader.vue';
import IsInViewWrapper from '@/components/IsInViewWrapper.vue';
import ShootingTypeChip from '@/components/ShootingTypeChip.vue';
import ChipCell from '@/components/table/cells/chip-cell.vue';
import TextCell from '@/components/table/cells/text-cell.vue';
import { usePreserveScroll } from '@/composables/usePreserveScroll';
import { IS_DEVELOPMENT as isDevelopment } from '@/config';
import {
  EBarcodeEvents,
  isSKUBarcode,
  useBarcodeScan,
} from '@/features/Barcode';
import { ScannerEmulator } from '@/features/ScannerEmulator';
import { router } from '@/router';
import { AppRoutes } from '@/router/data';
import { PageHeader, PageLayout } from '@/template';
import { BaseFilter, LinearLoader, UiImagePreview, UiTable } from '@/ui';
import { Search } from '@/ui';
import { getLang } from '@/utils/lang';

import { useSkusApi } from './composables';
import { skuStatusFilterConfiguration, skuStatusToColor } from './data';

const { t } = useI18n();
const route = useRoute();
const productContainer = ref<HTMLElement>();
const activeSkuIdForFocus = ref();

usePreserveScroll(productContainer);

const {
  skus,
  loaded,
  getInitialSkus,
  resetSkusFilters,
  getSkuByBarcode,
  popSku,
  handleFilterChange,
  isFilterSelected,
  handleLoadMore,
  query,
  resetSkus,
} = useSkusApi();

const handleBarcodeScan = async (barcode: string) => {
  const sku = await getSkuByBarcode(barcode);

  popSku(sku.id);
  activeSkuIdForFocus.value = sku.id;

  const element = document.getElementById(`row-${activeSkuIdForFocus.value}`);

  element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
};

const customRow = (record, index) => {
  if (!record) {
    return;
  }

  return {
    class:
      record?.id === activeSkuIdForFocus.value
        ? `ant-table-row-selected`
        : null,
    id: `row-${record?.id}`,
    'data-test-id': 'row__table',
  };
};

useBarcodeScan(EBarcodeEvents.SKU, handleBarcodeScan);

const columns = computed<TableColumnType[]>(() => [
  {
    dataIndex: 'photo',
    key: 'photo',
    align: 'center',
    title: t('photo'),
    customCell: (record) => ({
      onClick: () => goToProductPage(record.id),
    }),
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: t('nomenclature'),
    width: '40%',
    customCell: (record) => ({
      onClick: () => goToProductPage(record.id),
    }),
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: t('status'),
    customCell: (record) => ({
      onClick: () => goToProductPage(record.id),
    }),
  },
  {
    dataIndex: 'barcode',
    key: 'barcode',
    title: t('barcode'),
    customCell: (record) => ({
      onClick: () => goToProductPage(record.id),
    }),
  },
  {
    dataIndex: 'sku',
    key: 'sku',
    title: t('SKU'),
    customCell: (record) => ({
      onClick: () => goToProductPage(record.id),
    }),
  },
  {
    dataIndex: 'onPhotoStudioCount',
    key: 'onPhotoStudioCount',
    title: t('on photostudio'),
    customCell: (record) => ({
      onClick: () => goToProductPage(record.id),
    }),
  },
]);

const goToProductPage = (id) => {
  router.push({ name: AppRoutes.product.name, params: { id } });
};

onBeforeRouteLeave((to, _, next) => {
  if (to.name === AppRoutes.product.name) return next();
  nextTick(() => {
    resetSkusFilters();
    resetSkus();
  });
  next();
});

onMounted(async () => {
  if (!loaded.value) {
    resetSkusFilters();
    await getInitialSkus();
  }

  const barcode = String(route.query.barcode);

  if (route.query.barcode && isSKUBarcode(barcode)) {
    handleBarcodeScan(barcode);
  }
});
</script>

<style lang="stylus" scoped>

.container
  border 1px solid #f0f0f0
  border-radius 5px

  :deep()
    .ant-table-thead .ant-table-cell
      background-color gray-2

    .ant-skeleton-title
      margin 0

    .ant-table-container
      border 0 !important

    .ant-table-cell
      height 58px

      &:last-child
        border-right 0 !important

    table
      border-top 0 !important

    tr:last-child td
      border-bottom 0 !important

    td:first-child
      cursor pointer

::v-deep(.search)
  width 325px

::v-deep(.products-table-row)
  cursor pointer
</style>
