<template>
  <div class="flex-col px-[32px] py-[16px]">
    <div
      v-if="mode !== PhotoTaskModalMode.Create"
      class="flex justify-between mb-6 text-xxl font-semi-bold"
    >
      <span :style="'width: 150px'">
        {{ $t('modal.phototask.title', { id: state.photoTask?.id }) }}
      </span>

      <div v-if="canChangePhotoTask" class="flex gap-1">
        <UiButton
          v-if="!loading"
          type="primary"
          :style="`background: ${
            photoTasksStatusColor[
              state?.photoTask?.status || PhotoTaskStatus.Created
            ]
          }`"
        >
          {{
            $t(
              `phototasksStatus.${
                state?.photoTask?.status || PhotoTaskStatus.Created
              }`,
            )
          }}
        </UiButton>

        <UiTooltip
          v-if="canChangeStatus"
          placement="right"
          :title="`${$t('transition to status')} ${$t(
            `phototasksStatus.${nextStatus}`,
          )}`"
        >
          <UiButton
            type="dashed"
            :icon="h(RightOutlined)"
            @click.stop="handleChangeStatus"
          />
        </UiTooltip>
      </div>
    </div>

    <div ref="datesContainer" class="flex items-center gap-4 mb-4">
      <div class="basis-[120px] text-secondary text-[12px] font-medium">
        Дата готовности:
      </div>
      <div>
        <UiDatePicker
          style="width: 150px"
          format="DD.MM.YYYY"
          :value="toDayjs(currentPayload.dueDate)"
          :get-popup-container="() => datesContainer"
          :disabled="mode === PhotoTaskModalMode.Edit"
          @change="handleDateChange"
        />
      </div>
    </div>

    <div class="flex items-center gap-4 mb-4">
      <div class="basis-[120px] text-secondary text-[12px] font-medium">
        Инфографика:
      </div>
      <div>
        <UiCheckbox
          v-model:checked="currentPayload.infographicsOnly"
          :disabled="mode === PhotoTaskModalMode.Edit"
        >
          Только инфографика
        </UiCheckbox>
      </div>
    </div>

    <div class="flex items-center gap-4 mb-4">
      <div class="basis-[120px] text-secondary text-[12px] font-medium">
        Услуги:
      </div>
      <div class="flex-auto">
        <UiSelect
          v-model:value="currentPayload.serviceIds"
          mode="multiple"
          style="width: 100%"
          placeholder="Выберите услуги"
          max-tag-count="responsive"
          :options="formattedServices"
          :disabled="!canChangePhotoTask || !itsEditable"
          :field-names="{ label: 'name', value: 'id' }"
          :loading="state.serviceLoading"
          @blur="handleChangeServices(currentPayload.serviceIds)"
        />
      </div>
    </div>

    <div class="flex items-center gap-4 mb-4">
      <div class="basis-[120px] text-secondary text-[12px] font-medium">
        Продавец:
      </div>
      <div class="flex-auto">
        <UiSelect
          v-model:value="currentPayload.sellerId"
          show-search
          placeholder="Введите продавца"
          style="width: 100%"
          :default-active-first-option="false"
          :filter-option="false"
          :not-found-content="null"
          :options="options"
          :loading="isFetching"
          :disabled="mode === PhotoTaskModalMode.Edit"
          @search="handleSearch"
          @popupScroll="onScroll"
        />
      </div>
    </div>

    <div class="flex items-center gap-4 mb-4">
      <div class="basis-[120px] text-secondary text-[12px] font-medium">
        Контакты продавца:
      </div>
      <div class="flex-auto">
        <UiSpin :spinning="state.sellerContactLoading">
          <UiInput
            v-model:value="currentPayload.sellerContact"
            style="width: 100%"
            placeholder="Контакты продавца"
            :disabled="!canChangePhotoTask || !itsEditable"
            @blur="handleChangeSellerContact(currentPayload.sellerContact)"
          />
        </UiSpin>
      </div>
    </div>

    <div class="flex items-center gap-4 mb-4">
      <div class="basis-[120px] text-secondary text-[12px] font-medium">
        Требования:
      </div>
      <div class="flex-auto">
        <UiSpin :spinning="state.requirementsLoading">
          <UiTextarea
            v-model:value="currentPayload.requirements"
            :disabled="!canChangePhotoTask || !itsEditable"
            placeholder="Напишите требования"
            auto-size
            @blur="handleChangeRequirements(currentPayload.requirements)"
          />
        </UiSpin>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RightOutlined } from '@ant-design/icons-vue';
import dayjs, { Dayjs } from 'dayjs';
import { computed, h, onMounted, ref } from 'vue';

import { PhotoTaskStatus, ShootingType } from '@/api';
import { useAuth } from '@/features/Auth';
import { usePhotoTask } from '@/features/Modals/photoTask/composables/usePhotoTask';
import { useSellers } from '@/features/Modals/photoTask/composables/useSellers';
import { useServices } from '@/features/Modals/photoTask/composables/useServices';
import {
  PhotoTaskModalMode,
  photoTasksStatusColor,
} from '@/features/Modals/photoTask/data';
import {
  UiButton,
  UiCheckbox,
  UiDatePicker,
  UiInput,
  UiSelect,
  UiSpin,
  UiTextarea,
  UiTooltip,
} from '@/ui';

interface Props {
  mode: PhotoTaskModalMode;
  loading: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'invalidatePhotoTasks', payload: PhotoTaskStatus): void;
}>();

const { services, getServices } = useServices(ShootingType.Instructional);

const formattedServices = computed(() => {
  return services.value.map((service) => ({
    ...service,
    className: 'custom-option',
  }));
});

const { options, isFetching, search, loadMore } = useSellers(50);

const {
  state,
  currentPayload,
  nextStatus,
  canChangeStatus,
  changeStatus,
  updateSellerContact,
  updateRequirements,
  updateServices,
} = usePhotoTask();

const { canChangePhotoTask } = useAuth();

// Редактировать фототаск можно только в статусах Создано и На согласовании
const itsEditable = computed(() => {
  if (props.mode === PhotoTaskModalMode.Create) {
    return true;
  }

  return state.photoTask
    ? [PhotoTaskStatus.Created, PhotoTaskStatus.InApproval].includes(
        state.photoTask.status,
      )
    : false;
});

onMounted(async () => {
  await getServices(ShootingType.Instructional);
});

const datesContainer = ref<HTMLElement>();

const handleDateChange = (date: Dayjs) => {
  if (date) {
    state.createPhotoTaskPayload.dueDate = date.startOf('date').valueOf(); // Сохраняем в формате секунд
  } else {
    state.createPhotoTaskPayload.dueDate = 0;
  }
};

const toDayjs = (date: number) => {
  if (date === 0 || !date) {
    return null;
  }

  return dayjs(date); // Преобразуем в dayjs, если дата валидна
};

const handleSearch = (val: string) => {
  search(val); // Выполняем поиск на сервере с параметром запроса
};

const onScroll = (event: Event) => {
  const target = event.target as HTMLElement;

  if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
    loadMore();
  }
};

const handleChangeServices = async (ids: number[]) => {
  if (!state.photoTask) {
    return;
  }

  const photoTask = await updateServices(ids);

  emit('invalidatePhotoTasks', photoTask.status);
};

const handleChangeSellerContact = async (contact: string) => {
  if (!state.photoTask) {
    return;
  }

  const photoTask = await updateSellerContact(contact);

  emit('invalidatePhotoTasks', photoTask.status);
};

const handleChangeRequirements = async (requirements: string) => {
  if (!state.photoTask) {
    return;
  }

  const photoTask = await updateRequirements(requirements);

  emit('invalidatePhotoTasks', photoTask.status);
};

const handleChangeStatus = async () => {
  if (!state.photoTask) {
    return;
  }

  const photoTask = await changeStatus();

  if (!photoTask) {
    return;
  }

  emit('invalidatePhotoTasks', photoTask.status);
};
</script>

<style lang="stylus">
.custom-option  div {
  white-space: normal !important  ;
}
</style>
